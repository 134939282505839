import { LocalizedValue } from "../../../common/domain/models";
import { SnackRepository } from "../data/SnackRepository";
import { Snack } from "./models";

const snackRepository = new SnackRepository();

const getList = async (journeyUuid: string): Promise<Snack[]> => {
  return await snackRepository.getListByJourneyUuid(journeyUuid);
}

const createSnack = async (journeyUuid: string, title: LocalizedValue, description: LocalizedValue, mediaKey: string | null): Promise<void> => {
  return await snackRepository.create({ journeyUuid, title, description, mediaKey });
}

const editSnack = async (uuid: string, title: LocalizedValue, description: LocalizedValue, mediaKey: string | null): Promise<void> => {
  return await snackRepository.edit({ uuid, title, description, mediaKey });
};

const deleteSnack = async (uuid: string): Promise<void> => {
  return await snackRepository.delete(uuid);
}

const snackServices = {
  getList,
  createSnack,
  editSnack,
  deleteSnack,
}

export default snackServices;