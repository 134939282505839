import { Button, Checkbox, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguagePicker from "../../../common/components/LanguagePicker";
import Transition from "../../../common/components/Transition";
import HintList from "./HintList";

const QuestionDialog = (props) => {
  const component = props.component;
  const [language, setLanguage] = useState(props.languages[0])
  const [points, setPoints] = useState(component.points);
  const [incorrectDeductionPoints, setIncorrectDeductionPoints] = useState(component.incorrectDeductionPoints ? component.incorrectDeductionPoints : 0);
  const [answer, setAnswer] = useState(component.answer);
  const [hintReminderTime, setHintReminderTime] = useState(component.hintReminderTime ? component.hintReminderTime : 0);
  const [maxAnswers, setMaxAnswers] = useState(component.maxAnswers ? component.maxAnswers : 0);
  const [levenshteinDistance, setLevenshteinDistance] = useState(component.levenshteinDistance ? component.levenshteinDistance : 0);
  const [useSounds, setUseSounds] = useState(component.useSounds ? component.useSounds : false);
  const [useConfetti, setUseConfetti] = useState(component.useConfetti !== undefined ? component.useConfetti : true);
  const [hints, setHints] = useState(component.hints);

  const handleAnswerUpdated = useCallback((answer) => {
    setAnswer(answer);
  }, []);

  const onConfirm = () => {
    const updatedComponent = {
      ...props.component,
      points: parseInt(points),
      incorrectDeductionPoints: parseInt(incorrectDeductionPoints),
      answer: answer,
      hintReminderTime: parseInt(hintReminderTime),
      maxAnswers: parseInt(maxAnswers),
      levenshteinDistance: parseInt(levenshteinDistance),
      useSounds: useSounds,
      useConfetti: useConfetti,
      hints: hints,
    }

    props.onSave(updatedComponent);
    props.onClose();

  }

  return (
    <Dialog open={ true } TransitionComponent={ Transition } maxWidth="lg" onClose={ (e) => { props.onClose() } }>
      <DialogContent>
        <div className="question-dialog dialog-content">
          <div className="settings-header">
            <h2>Question settings</h2>
            <LanguagePicker currentLanguage={ language } languages={ props.languages } onChange={ setLanguage } />
          </div>
          <h3>Basic settings:</h3>
          <div className="basic-settings">
            <div className="points">
              <TextField variant="filled" label="Points" value={ points } type="number" onChange={ (e) => setPoints(e.target.value) } size="small" />
            </div>
            <div className="incorrect-deduction-points">
              <TextField variant="filled" label="Points for wrong answer" value={ incorrectDeductionPoints } type="number" onChange={ (e) => setIncorrectDeductionPoints(e.target.value) } size="small" />
            </div>
            <div className="hint-reminder">
              <TextField variant="filled" label="Hint reminder (min)" value={ hintReminderTime } type="number" onChange={ (e) => setHintReminderTime(e.target.value) } size="small" />
            </div>
            <div className="max-answers">
              <TextField variant="filled" label="Max answers" value={ maxAnswers } type="number" onChange={ (e) => setMaxAnswers(e.target.value) } size="small" />
            </div>
            <div className="levenshtein">
              <TextField variant="filled" label="Levenshtein" value={ levenshteinDistance } type="number" onChange={ (e) => setLevenshteinDistance(e.target.value) } size="small" />
            </div>
            <div className="use-sounds">
              <span>Sounds</span>
              <Checkbox
                checked={ useSounds }
                onChange={ (e) => setUseSounds(e.target.checked) }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div className="use-confetti">
              <span>Confetti</span>
              <Checkbox
                checked={ useConfetti }
                onChange={ (e) => setUseConfetti(e.target.checked) }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
          </div>
          <AnswerList answer={ answer } onUpdate={ handleAnswerUpdated } />
          <HintList languages={ props.languages } language={ language } hints={ hints } onUpdate={ (hints) => setHints(hints) } />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => props.onClose() }>
          Cancel
        </Button>
        <Button onClick={ (e) => onConfirm() }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDialog);

/**
 * Initially, only one answer to a question could be correct.
 * To maintain backwards compatibility, I (Rien) decided to maintain the same data type (string), but split the answers using ;
 * That way, existing challenge data doesn't have to be reformatted, and the chance of ";" being used as part of an actual answer is nonexistent.
 */
function AnswerList(props) {
  const componentAnswer = props.answer ? props.answer : '';
  const onUpdate = props.onUpdate;
  const [answers, setAnswers] = useState(componentAnswer.split(';'));

  useEffect(() => {
    const nonEmptyAnswers = answers.filter((answer) => {
      return answer.length > 0;
    });

    onUpdate(nonEmptyAnswers.join(';'));
  }, [onUpdate, answers]);

  const updateAnswer = (value, index) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  }

  const handleAddAnswerClicked = () => {
    setAnswers([...answers, '']);
  }

  return <div className="answer">
    <h3>Answers</h3>
    {
      answers.map((answer, index) => {
        return <TextField key={ index } variant="filled" label={ "Answer " + (index + 1) } value={ answer } onChange={ (e) => updateAnswer(e.target.value, index) } size="small" fullWidth={ true } />;
      })
    }
    <Button className='add-answer-button' variant="contained" color="primary" onClick={ (e) => handleAddAnswerClicked() }>Add answer</Button>
  </div>
}