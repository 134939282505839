import { Button, IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PersonIcon from "@material-ui/icons/Person";
import ChallengeList from "../../challenge/component/ChallengeList";
import { copyChallenge } from "../../challenge/redux/challengeOperations";
import { JourneyList } from "../../journey/component/JourneyList";

const CustomerListItem = (props) => {
  const customer = props.customer;

  return (
    <Paper elevation={ 2 } className="customer-list-item">
      <div className="customer-list-item-header">
        <h2><strong className="text-content">{ customer.title }</strong></h2>

        {
          props.canPaste
            ? <Button variant="contained" color="primary" onClick={ (e) => props.copyChallenge(customer) }>Paste</Button>
            : <div className="customer-list-item-controls">
              <div className="customer-list-item-color" style={ { backgroundColor: customer.colors.primaryColor } } />
              <Button variant="contained" color="primary" startIcon={ <EditIcon /> } onClick={ (e) => props.onEdit(customer) }>Edit</Button>

              <Button variant="contained" color="primary" startIcon={ <PersonIcon /> } onClick={ (e) => props.onOpenPlayerDialog(customer) }>Players</Button>
              <IconButton color="primary"
                onClick={ () => { props.open ? props.onClose() : props.onOpen() }
                }>
                { props.open ? <RemoveIcon /> : <AddIcon /> }
              </IconButton>
            </div>
        }

      </div>
      <div>
        {
          props.open
            ? <>
              <ChallengeList customer={ customer } />
              <JourneyList customerUuid={ customer.uuid } />
            </>
            : <div />
        }
      </div>
    </Paper>
  )
}


const mapStateToProps = (state) => ({
  canPaste: state.challenge.challengeToCopyUuid != null,
});

const mapDispatchToProps = (dispatch) => ({
  copyChallenge: (customer) => dispatch(copyChallenge(customer.uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListItem);