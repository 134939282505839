
import { connect } from "react-redux";
import LoginForm from "./LoginForm";

export function LoginScreen() {
  return (
    <div>
      <div className="login-form-wrapper">
        <div className="logo large"></div>
        <LoginForm />
      </div>

    </div>
  )
}


const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);