import { Event } from "./models";

const mapJsonList = (jsonList: any[]): Event[] => {
  return jsonList.map(mapJson);
}

const mapJson = (json: any): Event => {
  return {
    uuid: json.uuid,
    title: json.title,
    duration: json.duration,
    weight: json.weight,
    timings: json.timings ? {
      activeFrom: new Date(json.timings.activeFrom),
      activeUntil: new Date(json.timings.activeUntil),
      visibleFrom: new Date(json.timings.visibleFrom),
      visibleUntil: new Date(json.timings.visibleUntil),
    } : null,
    emailPlayerAfterCompletion: json.emailPlayerAfterCompletion,
  }
}

const eventMappers = {
  mapJsonList,
  mapJson,
};

export default eventMappers;