import { Button, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchList } from "../redux/eventOperations";
import AddIcon from "@material-ui/icons/Add";
import EventListItem from "./EventListItem";
import { Event } from "../data/models";
import eventMappers from "../data/mappers";

interface EventListProps {
  fetchList: any,
  challengeUuid: string,
  events?: Event[],
}

const EventList = (props: EventListProps) => {
  const { fetchList, challengeUuid } = props;
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);

  useEffect(() => {
    fetchList(challengeUuid);
  }, [fetchList, challengeUuid]);

  const onCreateEventPressed = () => {
    setIsCreatingEvent(true);
  };

  return (
    <div>
      <Paper elevation={1} className="event-list">
        <h2>Events:</h2>
        {props.events != null && props.events.map(event => {
          return <EventListItem key={event.uuid} event={event} />
        })}
        {isCreatingEvent && <EventListItem key={'new'} onCreated={() => setIsCreatingEvent(false)} />}

        <div className="event-list-controls">
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={(e) => onCreateEventPressed()}>Add new event</Button>
        </div>

      </Paper>
    </div>
  )
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    events: eventMappers.mapJsonList(state.event.currentEventList),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchList: (challengeUuid: string) => dispatch(fetchList(challengeUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventList);