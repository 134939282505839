import { generateShortKey } from "../../../util/uniqueIdUtil";
import { GenericScreenComponentConfiguratorProps } from "../component/ScreenComponentRenderer";
import { ScreenImageComponentConfigurator } from "../component/ScreenImageComponentConfigurator";
import { ScreenMultipleChoiceComponentConfigurator } from "../component/ScreenMultipleChoiceComponentConfigurator";
import { ScreenOpenQuestionComponentConfigurator } from "../component/ScreenOpenQuestionComponentConfigurator";
import { ScreenPhotoUploadComponentConfigurator } from "../component/ScreenPhotoUploadComponentConfigurator";
import { ScreenPollComponentConfigurator } from "../component/ScreenPollComponentConfigurator";
import { ScreenQuestionComponentConfigurator } from "../component/ScreenQuestionComponentConfigurator";
import { ScreenRichTextComponentConfigurator } from "../component/ScreenRichTextComponentConfigurator";
import { ScreenTitleComponentConfigurator } from "../component/ScreenTitleComponentConfigurator";
import { ScreenVideoComponentConfigurator } from "../component/ScreenVideoComponentConfigurator";
import { GenericScreenComponent, ScreenComponentType, ScreenImageComponent, ScreenMultipleChoiceComponent, ScreenOpenQuestionComponent, ScreenPhotoUploadComponent, ScreenPollComponent, ScreenQuestionComponent, ScreenRichTextComponent, ScreenTitleComponent, ScreenVideoComponent } from "./models";

interface ScreenComponentBehaviour {
  defaultValueFactory: (key: string) => GenericScreenComponent,
  configurator: React.FunctionComponent<GenericScreenComponentConfiguratorProps>,
}

const screenComponentBehaviourMap: Record<ScreenComponentType, ScreenComponentBehaviour> = {
  [ScreenComponentType.TITLE]: {
    defaultValueFactory: createDefaultTitleComponent,
    configurator: ScreenTitleComponentConfigurator,
  },
  [ScreenComponentType.RICH_TEXT]: {
    defaultValueFactory: createDefaultRichTextComponent,
    configurator: ScreenRichTextComponentConfigurator,
  },
  [ScreenComponentType.IMAGE]: {
    defaultValueFactory: createDefaultImageComponent,
    configurator: ScreenImageComponentConfigurator,
  },
  [ScreenComponentType.VIDEO]: {
    defaultValueFactory: createDefaultVideoComponent,
    configurator: ScreenVideoComponentConfigurator,
  },
  [ScreenComponentType.QUESTION]: {
    defaultValueFactory: createDefaultQuestionComponent,
    configurator: ScreenQuestionComponentConfigurator,
  },
  [ScreenComponentType.MULTIPLE_CHOICE]: {
    defaultValueFactory: createDefaultMultipleChoiceComponent,
    configurator: ScreenMultipleChoiceComponentConfigurator,
  },
  [ScreenComponentType.PHOTO_UPLOAD]: {
    defaultValueFactory: createDefaultPhotoUploadComponent,
    configurator: ScreenPhotoUploadComponentConfigurator,
  },
  [ScreenComponentType.OPEN_QUESTION]: {
    defaultValueFactory: createDefaultOpenQuestionComponent,
    configurator: ScreenOpenQuestionComponentConfigurator,
  },
  [ScreenComponentType.POLL]: {
    defaultValueFactory: createDefaultPollComponent,
    configurator: ScreenPollComponentConfigurator,
  },
}

const createDefaultComponentForType = (type: ScreenComponentType): GenericScreenComponent => {
  const key = generateShortKey();

  return screenComponentBehaviourMap[type].defaultValueFactory(key);
}

const configuratorForType = (type: ScreenComponentType): React.FunctionComponent<GenericScreenComponentConfiguratorProps> => {
  return screenComponentBehaviourMap[type].configurator;
}

const screenComponentServices = {
  createDefaultComponentForType,
  configuratorForType,
};

export default screenComponentServices;


function createDefaultTitleComponent(key: string): ScreenTitleComponent {
  return {
    key,
    type: ScreenComponentType.TITLE,
    text: { nl: 'New title' },
  };
}

function createDefaultRichTextComponent(key: string): ScreenRichTextComponent {
  return {
    key,
    type: ScreenComponentType.RICH_TEXT,
    richText: { nl: '' },
  };
}

function createDefaultImageComponent(key: string): ScreenImageComponent {
  return {
    key,
    type: ScreenComponentType.IMAGE,
  };
}

function createDefaultVideoComponent(key: string): ScreenVideoComponent {
  return {
    key,
    type: ScreenComponentType.VIDEO,
  };
}

function createDefaultQuestionComponent(key: string): ScreenQuestionComponent {
  return {
    key,
    type: ScreenComponentType.QUESTION,
    question: { nl: 'New question' },
    answer: '',
    points: 100,
    hints: [],
    incorrectDeductionPoints: 0,
    hintReminderTime: 0,
    maxAnswers: 0,
    levenshteinDistance: 0,
    useSounds: true,
    useConfetti: true,
  };
}

function createDefaultMultipleChoiceComponent(key: string): ScreenMultipleChoiceComponent {
  return {
    key,
    type: ScreenComponentType.MULTIPLE_CHOICE,
    question: { nl: 'New multiple choice' },
    answers: [],
    points: 100,
    incorrectDeductionPoints: 0,
    maxAnswers: 0,
    useSounds: true,
    useConfetti: true,
  };
}

function createDefaultPhotoUploadComponent(key: string): ScreenPhotoUploadComponent {
  return {
    key,
    type: ScreenComponentType.PHOTO_UPLOAD,
    title: { nl: 'New photo upload' },
    points: 0,
    requiredForValidation: false,
    moderationRequired: false,
  };
}

function createDefaultOpenQuestionComponent(key: string): ScreenOpenQuestionComponent {
  return {
    key,
    type: ScreenComponentType.OPEN_QUESTION,
    question: { nl: 'New open question' },
    maxCharacters: null,
    requiredForScreenValidation: true,
    showInDashboard: false,
  };
}

function createDefaultPollComponent(key: string): ScreenPollComponent {
  return {
    key,
    type: ScreenComponentType.POLL,
    question: { nl: 'New poll question' },
    answers: [],
  }
}
