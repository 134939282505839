import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import Header from "../../../common/components/Header";
import Page from "../../../common/components/Page";
import CustomerList from "./CustomerList";

const CustomerOverviewScreen = (props) => {
  return (
    <Page>
      <Header title="Dashboard" />
      <Paper elevation={ 0 } className="page-detail">
        <CustomerList />
      </Paper>
    </Page>
  );
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOverviewScreen);