import { Button, IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FileUploadIcon, SaveIcon } from "../../../common/components/icons";

const ImageEditForm = (props) => {
  const [imageSource, setImageSource] = useState(null);
  const [tags, setTags] = useState(null);

  useEffect(() => {
    if (props.mediaItem.key != null) {
      setImageSource(props.mediaItem.url);
      setTags(props.mediaItem.tags ? props.mediaItem.tags : '');
    } else {
      setImageSource(null);
      setTags('');
    }
  }, [props.mediaItem]);

  const handleFileSelected = (e) => {
    const selectedFile = e.target.files[0];

    var reader = new FileReader();
    reader.onload = function() {
      setImageSource(reader.result);      
    }
    reader.readAsDataURL(selectedFile);
  }

  const onSavePressed = () => {
    const data = props.mediaItem.key ? null : {base64Data: imageSource};
    props.onSave(data, tags);
    setImageSource(null);
    setTags(null);
  }
  
  return (
    <div className="media-edit-form">
      {
        imageSource === null 
        ? <div><form>
            <input accept="image/*" type="file" id={"media-image-upload"} onChange={handleFileSelected} value="" hidden/>
            <label htmlFor={"media-image-upload"}>
              <IconButton component="span" color="primary">
                <FileUploadIcon />
              </IconButton>
            </label>
            </form>
          </div>
        : <img className="media-form-image" alt="" src={imageSource} />
      }      
      
      <TextField variant="filled" label="Search tags" value={tags || ''} onChange={(e) => setTags(e.target.value)} size="small" fullWidth={true} InputLabelProps={{shrink:true}}/>
      <Button variant="contained" color="primary" startIcon={<SaveIcon/>} onClick={(e) => {onSavePressed()}} disabled={imageSource==null}>Save</Button>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditForm);