import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { AddIcon, DeleteIcon, EditIcon, RemoveIcon } from "../../../common/components/icons";
import { LanguageContext } from "../../../common/components/LanguagePicker";
import { LocalizedValue } from "../../../common/domain/models";
import { extraConfirm } from "../../../util/promptUtil";
import MediaDialog, { MediaType } from "../../media/component/MediaDialog";
import { ImageMediaItem } from "../../media/data/models";
import { SnackScreenList } from "../../snack_screen/component/SnackScreenList";
import { Snack } from "../domain/models";
import snackServices from "../domain/snackServices";

interface SnackListProps {
  journeyUuid: string;
}

export function SnackList({ journeyUuid }: SnackListProps) {
  const [snacks, setSnacks] = useState<Snack[]>([]);
  const [isAddingSnacks, setIsAddingSnacks] = useState(false);

  const getSnacks = useCallback(async () => {
    const snacks = await snackServices.getList(journeyUuid);
    setSnacks(snacks);
  }, [journeyUuid]);

  const onSaveNewSnack = async (title: LocalizedValue, description: LocalizedValue, mediaKey: string | null) => {
    await snackServices.createSnack(journeyUuid, title, description, mediaKey);
    await getSnacks();
    setIsAddingSnacks(false);
  };

  useEffect(() => {
    getSnacks();
  }, [getSnacks]);

  return <Paper className='snack-list'>
    <h2>Snacks</h2>
    {
      snacks.map((snack) => <SnackListItem key={ snack.uuid } snack={ snack } onChange={ getSnacks } />)
    }
    {
      isAddingSnacks
        ? <SnackEditor onSaveChanges={ onSaveNewSnack } />
        : <Button variant="contained" color="primary" onClick={ (e) => setIsAddingSnacks(true) }>Add new snack</Button>
    }

  </Paper>;
}

interface SnackListItemProps {
  snack: Snack;
  onChange: () => void;
}

function SnackListItem({ snack, onChange }: SnackListItemProps) {
  const language = useContext(LanguageContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const handleDeleteClicked = async () => {
    if (extraConfirm('Are you sure you want to delete this snack? type "yes" to confirm')) {
      await snackServices.deleteSnack(snack.uuid);
      onChange();
    }
  };

  const handleEditConfirmed = async (title: LocalizedValue, description: LocalizedValue, mediaKey: string | null) => {
    await snackServices.editSnack(snack.uuid, title, description, mediaKey);
    onChange();
    setIsEditing(false);
  };

  return <Paper key={ snack.uuid } className='snack-list-item'>
    <div className='snack-list-item-header'>
      { !isEditing

        ? <>
          <div className='snack-list-item-info'>
            <SnackImage image={ snack.image } />
            <div>
              <h3>{ snack.title[language] }</h3>
              <p>{ snack.description[language] }</p>
            </div>
          </div>
          <div className='snack-list-item-controls'>
            <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
            <IconButton onClick={ (e) => handleDeleteClicked() }><DeleteIcon /></IconButton>
            {
              isOpen
                ? <IconButton onClick={ (e) => setIsOpen(false) }><RemoveIcon /></IconButton>
                : <IconButton onClick={ (e) => setIsOpen(true) }><AddIcon /></IconButton>
            }
          </div>

        </>
        : <SnackEditor snack={ snack } onSaveChanges={ handleEditConfirmed } />
      }
    </div>
    {
      isOpen && <div>
        <SnackScreenList snack={ snack } />
      </div>
    }
  </Paper>
}

interface SnackEditorProps {
  snack?: Snack;
  onSaveChanges: (title: LocalizedValue, description: LocalizedValue, mediaKey: string | null) => void;
}

function SnackEditor({ snack, onSaveChanges }: SnackEditorProps) {
  const language = useContext(LanguageContext);
  const [title, setTitle] = useState<LocalizedValue>(snack ? snack.title : { 'nl': 'New snack' });
  const [description, setDescription] = useState<LocalizedValue>(snack ? snack.description : { 'nl': 'New snack description' });
  const [image, setImage] = useState<ImageMediaItem | null>(snack ? snack.image : null);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const confirm = () => {
    onSaveChanges(title, description, image ? image.key : null);
  };

  const titleTextFieldValue = title[language] ? title[language] : '';
  const descriptionTextFieldValue = description[language] ? description[language] : '';

  console.log('media dialog poen', mediaDialogOpen);

  return <div className='snack-editor'>
    <SnackImage image={ image } onClick={ () => setMediaDialogOpen(true) } />
    <TextField className="form-textfield" onChange={ (e) => setTitle({ ...title, [language]: e.target.value }) } label="Title" variant="filled" value={ titleTextFieldValue } />
    <TextField className="form-textfield" onChange={ (e) => setDescription({ ...description, [language]: e.target.value }) } label="Description" variant="filled" value={ descriptionTextFieldValue } />
    <Button variant="contained" color="primary" onClick={ (e) => confirm() }>Save</Button>
    { mediaDialogOpen && <MediaDialog onClose={ () => setMediaDialogOpen(false) } onConfirm={ setImage } selectionType={ MediaType.IMAGE } /> }
  </div>;
}

interface SnackImageProps {
  image: ImageMediaItem | null,
  onClick?: () => void;
}

function SnackImage({ image, onClick }: SnackImageProps) {
  let className = 'snack-image' + (onClick ? ' editable' : '');
  return <div
    className={ className }
    onClick={ onClick }
    style={ { backgroundImage: image ? `url(${image.url})` : '' } }
  >
    {
      image
        ? <></>
        : <p>No image</p>
    }
  </div>
}