
import { IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import ScreenComponentContainer from "./ScreenComponentContainer";
import QuestionDialog from "../../question/component/QuestionDialog";

//TODO: languages

export const ScreenQuestionComponentConfigurator = (props) => {
  const component = props.component;
  const language = props.language;
  const [questionText, setQuestionText] = useState('');
  const [isEditing, setIsEditing] = useState(component.question ? false : true);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  useEffect(() => {
    setQuestionText(component.question ? (component.question[language] ? component.question[language] : '') : '');
  }, [language, component.question]);

  const onConfirm = () => {
    var updatedQuestion = {
      ...(component.question ? component.question : {})
    };
    updatedQuestion[language] = questionText;
    component.question = updatedQuestion;
    props.onSave(component);

    setIsEditing(false);
  }

  const openSettings = () => {
    setIsSettingsDialogOpen(true);
  }

  const onSaveSettings = (component) => {
    props.onSave(component);
  }

  return (
    <ScreenComponentContainer className="screen-question-component" component={ props.component } onDelete={ props.onDelete } type="Question">
      { isEditing
        ? <TextField variant="outlined" label="Text" value={ questionText } onChange={ (e) => setQuestionText(e.target.value) } onKeyPress={ (e) => { if (e.key === 'Enter') onConfirm() } } autoFocus />
        : <div className="screen-question-component-content">
          <p>Question: { questionText }</p>
          <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
          { component.question != null && <IconButton onClick={ (e) => openSettings() }><SettingsIcon /></IconButton> }
        </div>
      }
      { isSettingsDialogOpen && <QuestionDialog component={ component } onClose={ () => setIsSettingsDialogOpen(false) } onSave={ onSaveSettings } languages={ props.languages } /> }
    </ScreenComponentContainer>
  )

}
