import { createSlice } from "@reduxjs/toolkit";

export const challengeSlice = createSlice({
  name: 'challenge',
  initialState: {
    listPerCustomer: {},
    currentChallenge: null,
    challengeToCopyUuid: null,
  },
  reducers: {
    setListForCustomer: (state, action) => {
      state.listPerCustomer[action.payload.customerUuid] = action.payload.list;
    },
    setCurrentChallenge: (state, action) => {
      state.currentChallenge = action.payload;
    },
    setChallengeToCopyUuid: (state, action) => {
      state.challengeToCopyUuid = action.payload;
    },
  }
});

export const {
  setListForCustomer,
  setCurrentChallenge,
  setChallengeToCopyUuid,
} = challengeSlice.actions;

export default challengeSlice.reducer;