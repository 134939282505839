import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import * as eventUpdateServices from '../domain/eventUpdateServices';

const NO_FILTER = 'all';

const EventUpdateFilter = (props) => {
  const updates = props.updates;
  const onFilterUpdates = props.onFilterUpdates;
  const [selectedEventUuid, setSelectedEventUuid] = useState(NO_FILTER);
  const [selectedTeamUuid, setSelectedTeamUuid] = useState(NO_FILTER);
  const distinctEvents = eventUpdateServices.getDistinctEvents(updates);
  const distinctTeams = eventUpdateServices.getDistinctTeams(updates);

  useEffect(() => {
    const matchingUpdates = updates.filter((update) => {
      const eventMatches = selectedEventUuid === NO_FILTER || selectedEventUuid === update.event.uuid;
      const teamMatches = selectedTeamUuid === NO_FILTER || selectedTeamUuid === update.team.uuid;

      return eventMatches && teamMatches;
    });

    onFilterUpdates(matchingUpdates);
  }, [selectedEventUuid, selectedTeamUuid, updates, onFilterUpdates]);

  return <tr>
    <th>Date</th>
    <th>
      Event<br />
      <FormControl>
        <Select
          value={ selectedEventUuid }
          onChange={ (e) => setSelectedEventUuid(e.target.value) }
        >
          <MenuItem value={ NO_FILTER }>All</MenuItem>
          {
            distinctEvents.map((event) => {
              return <MenuItem key={ event.uuid } value={ event.uuid }>{ event.title }</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </th>
    <th>Screen</th>
    <th>
      Team <br />
      <FormControl>
        <Select
          value={ selectedTeamUuid }
          onChange={ (e) => setSelectedTeamUuid(e.target.value) }
        >
          <MenuItem value={ NO_FILTER }>All</MenuItem>
          {
            distinctTeams.map((team) => {
              return <MenuItem key={ team.uuid } value={ team.uuid }>{ team.name }</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </th>
    <th>Player</th>
    <th>Type</th>
    <th>Data</th>
    <th>Score</th>
  </tr>;
}

export default EventUpdateFilter;