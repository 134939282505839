import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import { useState } from "react";
import LanguagePicker, { Language } from "../../../common/components/LanguagePicker";
import { DefaultDialogTransition } from "../../../helpers/ui";
import { ScreenPollComponent } from "../../screen_component/domain/models";
import { PollAnswerList } from "./PollAnswerList";

interface PollDialogProps {
  component: ScreenPollComponent,
  languages: Language[],
  onSave: (component: ScreenPollComponent) => void,
  onClose: () => void,
}

const PollDialog = (props: PollDialogProps) => {
  const component = props.component;
  const [language, setLanguage] = useState(props.languages[0]);
  const [question, setQuestion] = useState(component.question);
  const [answers, setAnswers] = useState(component.answers);

  const onConfirm = () => {
    const updatedComponent = {
      ...props.component,
      question: question,
      answers: answers,
    }

    props.onSave(updatedComponent);
    props.onClose();
  }

  return (
    <Dialog open={ true } TransitionComponent={ DefaultDialogTransition } maxWidth="lg" onClose={ (e) => { props.onClose() } }>
      <DialogContent>
        <div className="question-dialog dialog-content multiple-choice-dialog">
          <div className="settings-header">
            <h2>Poll settings</h2>
            <LanguagePicker currentLanguage={ language } languages={ props.languages } onChange={ setLanguage } />
          </div>
          <TextField variant="outlined" label="Poll question" value={ question[language] } onChange={ (e) => setQuestion({ ...question, [language]: e.target.value }) } onKeyPress={ (e) => { if (e.key === 'Enter') onConfirm() } } autoFocus />
          <PollAnswerList languages={ props.languages } language={ language } answers={ answers } onUpdate={ (answers) => setAnswers(answers) } />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => props.onClose() }>
          Cancel
        </Button>
        <Button onClick={ (e) => onConfirm() }>
          Save
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default PollDialog;