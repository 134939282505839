import { Language } from "../../../common/components/LanguagePicker";
import { GenericScreenComponent } from "../domain/models";
import screenComponentServices from "../domain/screenComponentServices";
import React from "react";

interface ScreenComponentRendererProps {
  component: GenericScreenComponent;
  onSaveComponent: (component: GenericScreenComponent) => void;
  onDeleteComponent: (component: GenericScreenComponent) => void;
  language: Language,
  availableLanguages: Language[],
  index: number,
}

export function ScreenComponentRenderer(props: ScreenComponentRendererProps) {
  const componentProps: GenericScreenComponentConfiguratorProps = {
    key: props.component.key,
    component: props.component,
    onSave: props.onSaveComponent,
    onDelete: props.onDeleteComponent,
    language: props.language,
    languages: props.availableLanguages,
  };

  const configurator = screenComponentServices.configuratorForType(props.component.type);

  return <>
    {
      React.createElement(configurator, componentProps)
    }
  </>;
}

export interface GenericScreenComponentConfiguratorProps {
  key: string,
  component: GenericScreenComponent,
  onSave: (component: GenericScreenComponent) => void,
  onDelete: (component: GenericScreenComponent) => void,
  language: Language,
  languages: Language[],
}