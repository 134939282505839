import { createSlice } from "@reduxjs/toolkit";

export const teamSlice = createSlice({
  name: 'team',
  initialState: {
    activeContext: {
      challengeUuid: null,
      teamInfo: {
        teams: [],
        unassignedPlayers: [],
      },
    }
  },
  reducers: {
    clearActiveContext: (state, action) => {
      state.activeContext = {
        challengeUuid: null,
        teamInfo: {
          teams: [],
          unassignedPlayers: [],
        },
      }
    },
    setInfoForActiveContext: (state, action) => {
      state.activeContext.challengeUuid = action.payload.challengeUuid;
      state.activeContext.teamInfo = action.payload.info;
    },
  }
});

export const {
  clearActiveContext,
  setInfoForActiveContext,
} = teamSlice.actions;

export default teamSlice.reducer;