import ApplicationConfig from "../../../ApplicationConfig";
import { ApiError } from "../../../common/domain/models";
import * as apiHelper from "../../../helpers/apiHelpers";

export class ChallengeRepository {

  async fetchList(customerUuid) {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/challenge/list?customer_uuid=' + customerUuid,
      {}
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error fetching challenge list', response);
  }

  async fetchChallenge(challengeUuid) {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/challenge/item/' + challengeUuid,
      {}
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error fetching challenge', response);
  }

  async generateShareToken(challengeUuid: string): Promise<string> {
    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/generate-share-token',
      { uuid: challengeUuid },
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody.shareToken;
    }

    throw new ApiError('Error fetching challenge share token', response);
  }

  async createChallenge(customerUuid, title) {
    const postBody = {
      title: title,
      customerUuid: customerUuid
    };

    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/create',
      postBody,
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error creating challenge', response);
  }

  async editChallenge(challenge) {
    const postBody = {
      uuid: challenge.uuid,
      title: challenge.title,
    };

    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/edit',
      postBody,
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error editing challenge', response);
  }

  async deleteChallenge(challenge) {
    const postBody = {
      uuid: challenge.uuid,
    };

    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/delete',
      postBody,
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error deleting challenge', response);
  }

  async resetChallenge(challenge) {
    const postBody = {
      uuid: challenge.uuid,
    };

    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/reset',
      postBody,
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error resetting challenge', response);
  }

  async activateChallenge(customerUuid, challengeUuid) {
    const postBody = {
      customerUuid: customerUuid,
      challengeUuid: challengeUuid,
    };

    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/activate',
      postBody,
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error activating challenge', response);
  }

  async copyChallenge(toCustomerUuid, challengeUuid) {
    const postBody = {
      toCustomerUuid: toCustomerUuid,
      challengeUuid: challengeUuid,
    };

    const response = await apiHelper.authenticatedJsonPost(
      ApplicationConfig.BACKEND_URL + '/challenge/copy',
      postBody,
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody;
    }

    throw new ApiError('Error copying challenge', response);
  }
}