
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { createContext } from "react";

export enum Language {
  ENGLISH = 'en',
  DUTCH = 'nl',
  FRENCH = 'fr',
}

interface LanguagePickerProps {
  languages: Language[];
  currentLanguage: Language;
  onChange: (language: Language) => void;
}

function LanguagePicker(props: LanguagePickerProps) {
  return (
    <FormControl className="language-picker" variant="filled">
      <InputLabel>Language</InputLabel>
      <Select value={ props.currentLanguage } onChange={ (e) => props.onChange(e.target.value as Language) }>
        { props.languages.map((language) => {
          return (
            <MenuItem key={ language } value={ language }>{ language }</MenuItem>
          )
        }) }
      </Select>
    </FormControl>
  );
}

export default LanguagePicker;

export const LanguageContext = createContext<Language>(Language.DUTCH);