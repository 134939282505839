
import { Button, TextField } from "@material-ui/core";
import { LocalizedValue } from "../../../common/domain/models";
import { generateShortKey } from "../../../util/uniqueIdUtil";
import { PollAnswer } from "../../screen_component/domain/models";

interface PollAnswerListProps {
  languages: string[],
  language: string,
  answers: PollAnswer[],
  onUpdate: (answers: PollAnswer[]) => void,
}

export const PollAnswerList = (props: PollAnswerListProps) => {

  const onAddAnswer = () => {
    props.onUpdate([
      ...props.answers,
      {
        key: generateShortKey(),
        title: props.languages.reduce((map, language) => {
          map[language] = 'Answer text (' + language + ')';
          return map;
        }, {}) as LocalizedValue,
      }
    ]);
  }

  const updateAnswerLanguageDependentProperty = (answerToUpdate, property, value) => {
    var languageDependentProperty = { ...answerToUpdate[property] };
    languageDependentProperty[props.language] = value;

    updateAnswerProperty(answerToUpdate, property, languageDependentProperty);
  }

  const updateAnswerProperty = (answerToUpdate, property, value) => {
    const updatedAnswers = props.answers.map((answer) => {
      if (answer.key === answerToUpdate.key) {
        var updatedAnswer = { ...answerToUpdate };
        updatedAnswer[property] = value;

        return updatedAnswer;
      } else {
        return answer;
      }
    });

    props.onUpdate(updatedAnswers);
  }

  return (
    <div className="answer-list">
      <h3>Answers:</h3>
      <div className="answer-list-item-wrapper">
        {
          props.answers.map((answer) => {
            return (
              <div key={ answer.key } className="answer-list-item">
                <TextField
                  className="answer-textfield"
                  variant="filled"
                  label="Title"
                  value={ answer.title[props.language] }
                  onChange={ (e) => updateAnswerLanguageDependentProperty(answer, 'title', e.target.value) }
                  size="small"
                />
              </div>
            );
          })
        }
      </div>
      <Button variant="contained" color="primary" onClick={ (e) => onAddAnswer() }>Add answer</Button>
    </div>
  );
}


