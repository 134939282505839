import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        auth: {
            token: null,
            refreshToken: null,
            loggedIn: false,
        },
        info: {
            firstName: null,
            lastName: null,
            companyName: null,
        },
    },
    reducers: {
        setAuthenticationInfo: (state, action) => {
            state.auth.token = action.payload.token;
            state.auth.refreshToken = action.payload.refreshToken;
        },
        setIsLoggedIn: (state, action) => {
            state.auth.loggedIn = action.payload;
        },
        setUserInfo: (state, action) => {
            state.info.firstName = action.payload.firstName;
            state.info.lastName = action.payload.lastName;
            state.info.companyName = action.payload.companyName;
        }
    }
});

export const {
    setAuthenticationInfo,
    setUserInfo,
    setIsLoggedIn,
} = userSlice.actions;

export default userSlice.reducer;