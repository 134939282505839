import { useParams } from 'react-router-dom';
import shareServices from '../domain/shareServices';
import { useQuery } from '@tanstack/react-query';
import { ChallengeShareData, SharedOpenQuestion } from '../domain/models';
import { PhotoUpload } from '../../photo_upload/domain/models';
import { createContext, useContext } from 'react';
import { PrivateImage } from '../../photo_upload/component/PhotoUploadList';
import { DownloadIcon, TrophyIcon } from '../../../common/components/icons';

const ShareTokenContext = createContext<string>('');
const ShareDataContext = createContext<ChallengeShareData>({} as ChallengeShareData);

const ShareChallengeScreen = () => {
  const { shareToken } = useParams();
  const { data, error } = useQuery({
    queryKey: ['sharedata'],
    queryFn: () => shareServices.getChallengeShareData(shareToken),
  });

  return <>
    {
      data != null
        ? <>
          <ShareTokenContext.Provider value={ shareToken }>
            <ShareDataContext.Provider value={ data }>
              <ShareChallengeView />
            </ShareDataContext.Provider>
          </ShareTokenContext.Provider>
        </>
        : error
          ? <p className='error-message'>Something went wrong. You might have an invalid URL, make sure you copy and pasted the full URL you received</p>
          : <>Loading</>
    }
  </>;
}

export default ShareChallengeScreen;



const ShareChallengeView = () => {
  const shareData = useContext(ShareDataContext);
  const shareToken = useContext(ShareTokenContext);
  const primaryColor = shareData.customer.colors.primaryColor;
  const sortedTeams = [...shareData.teamsWithScore].sort((a, b) => {
    return b.score - a.score
  });

  return <div className='share-challenge-view' style={ { backgroundColor: primaryColor } }>
    <div className='wrapper'>
      <div className='header'>
        <div className='title-wrapper'>
          <h1 style={ { color: primaryColor } }>{ shareData.title } <br />rapport</h1>
          <p>By Metis United</p>
        </div>
        <img src={ shareData.customer.logoUrl! } alt={ 'logo' } className='customer-logo' />
      </div>
      <h2 className='section-title'>De uitslag:</h2>

      <div className='team-results'>
        {
          sortedTeams.map((score, index) => {
            const names = shareServices.getTeamMembersString(score.team.members!);
            const position = index + 1;
            return <div key={ score.team.uuid } className='team-result'>
              { position === 1 ? <TrophyIcon htmlColor='#FFD500' className='team-trophy' /> : <></> }
              <div className='title' style={ { color: primaryColor } }>
                <h3>{ position }. { score.team.name }</h3>
                <h3>{ score.score } punten</h3>
              </div>
              <p>{ names }</p>
            </div>
          })
        }
      </div>

      {
        shareData.images.length > 0
          ? <>
            <h2 className='section-title'>De foto's:</h2>
            <div className='download-photos'>
              <a href={ shareServices.getChallengePhotoDownloadUrl(shareToken) }>Download alle foto's</a>
              <DownloadIcon htmlColor='#FFFFFF' className='icon' />
            </div>

            <PhotoUploadTeamList uploads={ shareData.images } />
          </>
          : <></>
      }

      {
        shareData.openQuestions.questions.length > 0
          ? <OpenQuestionRepliesView openQuestions={ shareData.openQuestions.questions } />
          : <></>
      }

      <h2 className='section-title'>Met plezier gebracht door:</h2>
      <div className='brought-to-you-by'>
        <a href='https://metisunited.be'>
          <div className='logo large'></div>
        </a>
      </div>
    </div>
  </div>;
}

interface ImageGridProps {
  uploads: PhotoUpload[],
}

const PhotoUploadTeamList = ({ uploads }: ImageGridProps) => {
  const shareData = useContext(ShareDataContext);
  const photoUploadsPerTeam = shareServices.sortPhotoUploadsPerTeam(uploads, shareData.teamsWithScore.map((teamScore) => teamScore.team));
  const primaryColor = shareData.customer.colors.primaryColor;

  return <div className='team-photo-uploads'>
    { photoUploadsPerTeam.map((teamPhotoUploads) => {
      const teamName = teamPhotoUploads.team.nickname ? teamPhotoUploads.team.nickname : teamPhotoUploads.team.name;

      return <div key={ teamPhotoUploads.team.uuid } className='team-item'>
        <div className='team-info'>
          <h3 className='team-name' style={ { color: primaryColor } }>{ teamName }</h3>
          {/* <span className='team-members'>Leden: { names }</span> */ }
        </div>
        {
          teamPhotoUploads.photoUploads.length > 0
            ? <div className='uploads'>
              {
                teamPhotoUploads.photoUploads.map((upload) => <PhotoUploadView key={ upload.id } upload={ upload } />)
              }
            </div>
            : <p>Geen foto's voor dit team</p>
        }
      </div>;
    }) }
  </div>;
}

interface PhotoUploadViewProps {
  upload: PhotoUpload,

}

const PhotoUploadView = ({ upload }: PhotoUploadViewProps) => {
  const shareToken = useContext(ShareTokenContext);
  const size = 293;

  return <div className='image-wrapper'>
    <PrivateImage imageUrl={ upload.privateImageUrl } shareToken={ shareToken } width={ size } />
    <div className='image-info'>
      <p><strong>Door:</strong> { upload.player.name }</p>
      <p><strong>Opdracht:</strong> { upload.data.componentTitle['nl'] }</p>
    </div>
  </div>;
}

interface OpenQuestionRepliesViewProps {
  openQuestions: SharedOpenQuestion[],
}

const OpenQuestionRepliesView = ({ openQuestions }: OpenQuestionRepliesViewProps) => {
  const shareData = useContext(ShareDataContext);
  const primaryColor = shareData.customer.colors.primaryColor;

  return <div className='open-questions-wrapper'>
    <h2 className='section-title'>Feedback (open vragen):</h2>
    <div className='open-questions-list'>
      {
        openQuestions.map((sharedQuestion) => {

          return <div className='open-question-item'>
            <h3 className='open-question-title' style={ { color: primaryColor } }>{ sharedQuestion.question }</h3>
            <div className='open-question-replies'>
              {
                sharedQuestion.replies.map((reply) => {
                  return <div className='open-question-reply' style={ { borderColor: primaryColor } }>
                    <p className='reply-text'>{ reply.reply }</p>
                    <p className='reply-team'>({ reply.name })</p>
                  </div>;
                })
              }
            </div>
          </div>;
        })
      }
    </div>
  </div>;
}