import { Button } from "@material-ui/core";
import { useCallback } from "react";
import { useEffect, useState } from "react"
import { CrossIcon, DoneIcon } from "../../../common/components/icons";
import photoUploadServices from "../domain/PhotoUploadServices";

export const PhotoUploadList = (props) => {
  const challengeUuid = props.challengeUuid;
  const [uploads, setUploads] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchUploads = useCallback(async () => {
    try {
      const uploads = await photoUploadServices.getList(challengeUuid);
      setUploads(uploads);
    } catch (e) {
      setErrorMessage('Something unexpected went wrong!');
    }
  }, [challengeUuid]);

  const moderateUpload = useCallback(async (upload, approved, rejectionReason) => {
    try {
      await photoUploadServices.moderateUpload(upload, approved, rejectionReason);
      await fetchUploads();
    } catch (e) {
      setErrorMessage('Something unexpected went wrong!');
    }
  }, [fetchUploads]);

  useEffect(() => {
    fetchUploads();
  }, [fetchUploads]);

  return (
    <div className="photo-upload-list">
      { uploads == null
        ? 'Please wait'
        : <>
          {
            uploads.map(upload => {
              return <div key={ upload.eventUpdateId } className='photo-upload-item'>
                <PrivateImage imageUrl={ upload.privateImageUrl } width={ 300 } />
                <PhotoModerationInfo upload={ upload } onModerate={ (approved, rejectionReason) => moderateUpload(upload, approved, rejectionReason) } />
              </div>
            })
          }
        </>
      }
      {
        errorMessage && <p>{ errorMessage }</p>
      }


    </div>
  )
}

interface PrivateImageProps {
  imageUrl: string,
  shareToken?: string,
  width?: number,
  height?: number,
}

export const PrivateImage = (props: PrivateImageProps) => {
  const imageUrl = props.imageUrl;
  const shareToken = props.shareToken;
  const [imageBlob, setImageBlob] = useState<string | null>(null);

  const fetchBlob = useCallback(async () => {
    const blob = await photoUploadServices.getPrivateImageBlobUrl(imageUrl, shareToken);
    setImageBlob(blob);

  }, [imageUrl, shareToken]);

  useEffect(() => {
    fetchBlob();
  }, [fetchBlob]);

  if (imageBlob === null) {
    return <div className='private-image-placeholder'></div>
  } else {
    return <img
      className='private-image'
      src={ imageBlob }
      width={ props.width }
      height={ props.height }
      alt='uploaded by player'
    />
  }
}

const PhotoModerationInfo = (props) => {
  const upload = props.upload;

  const onReject = () => {
    const reason = prompt('Why?');
    if (reason && reason.length > 0) {
      props.onModerate(false, reason);
    }
  }

  return <div className="photo-moderation-info">
    <div className='photo-moderation-details'>
      <p>
        By { upload.teamName } - { upload.playerName } <br />
        { upload.componentTitle }
      </p>

    </div>
    {
      upload.moderationRequired ?
        <>
          { upload.moderationStatus
            ? <p className='photo-moderation-status'>{ upload.moderationStatus } { upload.rejectionReason && '(' + upload.rejectionReason + ')' }</p>
            : <div className="photo-moderation-buttons">
              <Button variant="contained" color="primary" startIcon={ <CrossIcon /> } onClick={ (e) => onReject() }>Reject</Button>
              <Button variant="contained" color="primary" startIcon={ <DoneIcon /> } onClick={ (e) => props.onModerate(true) }>Approve</Button>
            </div>
          }
        </>
        : <></>
    }

  </div>

}