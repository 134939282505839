import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import formatHelpers from "../../../helpers/formatHelpers";
import { Snack } from "../../snack/domain/models";
import { CreateSnackScreenData } from "../data/models";
import { SnackScreenType } from "../domain/models";
import snackScreenServices from "../domain/snackScreenServices";

interface SnackScreenCreatorProps {
  snack: Snack,
  onCreated: () => void,
}

export function SnackScreenCreator({ snack, onCreated }: SnackScreenCreatorProps) {
  const [title, setTitle] = useState('');
  const [type, setType] = useState(SnackScreenType.IMAGE);

  const confirm = async () => {
    const data: CreateSnackScreenData = {
      snackUuid: snack.uuid,
      title,
      type,
    }
    await snackScreenServices.createSnackScreen(data);
    onCreated();
  };

  return <Paper className='snack-screen-creator'>
    <div className='snack-screen-creator-input'>
      <h4>New snack screen:</h4>
      <TextField className='form-textfield' onChange={ (e) => setTitle(e.target.value) } label='Title' variant='filled' value={ title } />
      <FormControl className='snack-screen-type-picker' variant='filled'>
        <InputLabel>Background type</InputLabel>
        <Select value={ type } onChange={ (e) => setType(e.target.value as SnackScreenType) }>
          {
            Object.values(SnackScreenType).map((type) => {
              return (
                <MenuItem key={ type } value={ type }>{ formatHelpers.capitalize(type) }</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </div>
    <div>
      <Button variant="contained" color="primary" onClick={ (e) => confirm() }>Save</Button>
    </div>
  </Paper>;
}