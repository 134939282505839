import { ZodSchema } from "zod";

const parseRequestBody = <T>(object: any, schema: ZodSchema): T => {
  return schema.parse(object);
}

const zodHelpers = {
  parseRequestBody,
}

export default zodHelpers;