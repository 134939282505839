import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class TeamRepository {

  async fetchInfo(challengeUuid) {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/team/info?challenge_uuid=' + challengeUuid,
      {}
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async createTeam(challengeUuid, team) {
    const postBody = {
      challengeUuid: challengeUuid,
      ...this.mapNewTeamToPostBody(team),
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/team/create',
      {
        headers: apiHelper.getAuthorizedDefaultHeaders(),
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async editTeam(team) {
    const postBody = {
      uuid: team.uuid,
      ...this.mapTeamEditToPostBody(team)
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/team/edit',
      {
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async assignPlayersToTeam(teamUuid, playerUuids) {
    const postBody = {
      teamUuid: teamUuid,
      playerUuids: playerUuids,
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/team/assign-players',
      {
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async unassignPlayer(challengeUuid, playerUuid) {
    const postBody = {
      challengeUuid: challengeUuid,
      playerUuid: playerUuid,
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/team/unassign-player',
      {
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async fetchTeamScores(challengeUuid) {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/team/scores?challenge_uuid=' + challengeUuid,
      {}
    );

    const responseBody = await response.json();
    return responseBody;
  }

  mapNewTeamToPostBody(team) {
    return {
      name: team.name,
    }
  }

  mapTeamEditToPostBody(team) {
    return {
      name: team.name,
    }
  }
}