import { LocalizedValue } from "../../../common/domain/models";
import { ImageMediaItem, VideoMediaItem } from "../../media/data/models";
import { ScreenMultipleChoiceComponent, ScreenOpenQuestionComponent, ScreenPhotoUploadComponent } from "../../screen_component/domain/models";
import { SnackScreenLayoutParameters } from "../data/models";

export interface SnackScreenBase {
  uuid: string;
  title: string,
  type: SnackScreenType,
  layoutParameters: SnackScreenLayoutParameters,
  weight: number,
}

export interface SnackVideoScreen extends SnackScreenBase {
  type: SnackScreenType.VIDEO,
  media?: VideoMediaItem,
  text: LocalizedValue,
  component?: SnackScreenComponent,
}

export interface SnackImageScreen extends SnackScreenBase {
  type: SnackScreenType.IMAGE,
  media?: ImageMediaItem,
  text: LocalizedValue,
  duration: number,
  component?: SnackScreenComponent,
}

export type SnackScreen = SnackVideoScreen | SnackImageScreen;
export type SnackScreenComponent = ScreenMultipleChoiceComponent | ScreenOpenQuestionComponent | ScreenPhotoUploadComponent;

export enum SnackScreenType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export enum SnackScreenContentPosition {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}