import { LocalizedValue } from "../../../common/domain/models";
import { JourneyRepository } from "../data/JourneyRepository";
import { Journey, JourneyDetail } from "./models";

const repository = new JourneyRepository();

const getList = async (customerUuid: string): Promise<Journey[]> => {
  return await repository.fetchListByCustomerUuid(customerUuid);
}

const getJourney = async (uuid: string): Promise<JourneyDetail> => {
  return await repository.fetchByUuid(uuid);
}

const createJourney = async (customerUuid: string, title: LocalizedValue, superTitle: LocalizedValue): Promise<boolean> => {
  return await repository.create({ customerUuid, title, superTitle });
}

const editJourney = async (uuid: string, title: LocalizedValue, superTitle: LocalizedValue): Promise<boolean> => {
  return await repository.edit({ uuid, title, superTitle });
}

const deleteJourney = async (uuid: string): Promise<boolean> => {
  return await repository.delete(uuid);
}

const activateJourney = async (customerUuid: string, journeyUuid: string): Promise<boolean> => {
  return await repository.activate(customerUuid, journeyUuid);
}

const journeyServices = {
  getList,
  getJourney,
  createJourney,
  editJourney,
  deleteJourney,
  activateJourney,
};

export default journeyServices; 