import { IconButton, Paper } from "@material-ui/core";
import { DeleteIcon, SettingsIcon, ToggleOffIcon, ToggleOnIcon } from "../../../common/components/icons";
import { Journey } from "../domain/models";
import { Link } from "react-router-dom";
import { extraConfirm } from "../../../util/promptUtil";

interface JourneyListItemProps {
  journey: Journey,
  onActivate: () => void,
  onDelete: () => void,
}

export function JourneyListItem({ journey, onActivate, onDelete }: JourneyListItemProps) {

  const handleDeleteClicked = () => {
    if (extraConfirm('Are you sure? This will delete the journey and EVERYTHING RELATED... (type yes to confirm)')) {
      onDelete();
    }
  }

  return (
    <Paper elevation={ 2 } className="journey-list-item">

      <div className="journey-list-item-contents">
        <div>
          <strong className="text-content">{ journey.title['nl'] }</strong>
          <IconButton color="primary" onClick={ () => { handleDeleteClicked() } }><DeleteIcon /></IconButton>
        </div>
        <div className="journey-list-item-actions">
          <span>{ journey.active ? "Active" : "Inactive" }</span>
          <IconButton color="primary" onClick={ () => { onActivate() } }>{ journey.active ? <ToggleOnIcon /> : <ToggleOffIcon /> }</IconButton>
          <Link to={ 'journey/' + journey.uuid }>
            <IconButton color="primary" onClick={ () => { } }><SettingsIcon /></IconButton>
          </Link>
        </div>
      </div>
    </Paper>
  )
}
