import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../features/customer/redux/customerSlice";
import challengeReducer from "../features/challenge/redux/challengeSlice";
import userReducer from "../features/user/redux/userSlice";
import eventReducer from "../features/event/redux/eventSlice";
import teamReducer from "../features/team/redux/teamSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
    reducer: {
        user: userReducer,
        customer: customerReducer,
        challenge: challengeReducer,
        event: eventReducer,
        team: teamReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector