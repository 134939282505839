import Datetime from 'react-datetime';
import moment from "moment";


interface DateTimePickerProps {
  label: string,
  value: Date,
  onChange: (date: Date) => void,
}

export function DateTimePicker(props: DateTimePickerProps) {
  const { label, value, onChange } = props;

  return <div className='date-time-picker'>
    <label><strong>{ label }</strong></label>
    <Datetime input={ false } dateFormat={ 'DD/MM/YYYY' } timeFormat={ 'HH:mm' } open={ true } value={ value } onChange={
      (e) => {
        const momentje = (e as moment.Moment);
        if (momentje.isValid && momentje.isValid()) {
          onChange(momentje.seconds(0).toDate());
        }
      }
    } />
  </div>;
}