
import { IconButton, TextField } from "@material-ui/core";
import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponentContainer from "./ScreenComponentContainer";
import { ScreenOpenQuestionComponent } from "../domain/models";
import { GenericScreenComponentConfiguratorProps } from "./ScreenComponentRenderer";
import { OpenQuestionSettingsDialog } from "./OpenQuestionSettingsDialog";
import { SettingsIcon } from "../../../common/components/icons";

export const ScreenOpenQuestionComponentConfigurator = (props: GenericScreenComponentConfiguratorProps) => {
  const component = props.component as ScreenOpenQuestionComponent;
  const language = props.language;
  const [question, setQuestion] = useState(component.question);
  const [isEditing, setIsEditing] = useState(false);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState<boolean>(false);

  const onConfirm = () => {
    props.onSave({
      ...component,
      question: question,
    });
    setIsEditing(false);
  }

  return (
    <ScreenComponentContainer className="screen-open-question-component" component={ component } onDelete={ props.onDelete } type="Open question">
      { isEditing
        ? <TextField variant="outlined" label="Open question" value={ question[language] } onChange={ (e) => setQuestion({ ...question, [language]: e.target.value }) } onKeyPress={ (e) => { if (e.key === 'Enter') onConfirm() } } autoFocus />
        : <div className="screen-open-question-component-content">
          <p>Question: { question[language] }</p>
          <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
          <IconButton onClick={ (e) => setIsSettingsDialogOpen(true) }><SettingsIcon /></IconButton>
          { isSettingsDialogOpen && <OpenQuestionSettingsDialog component={ component } onClose={ () => setIsSettingsDialogOpen(false) } onSave={ props.onSave } /> }
        </div>
      }
    </ScreenComponentContainer>
  )

}
