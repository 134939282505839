//TODO: actually use these models in the screen components feature

import { LocalizedValue } from "../../../common/domain/models";
import { ImageMediaItem, VideoMediaItem } from "../../media/data/models";

export enum ScreenComponentType {
  TITLE = 'title',
  RICH_TEXT = 'rich_text',
  IMAGE = 'image',
  VIDEO = 'video',
  QUESTION = 'question',
  MULTIPLE_CHOICE = 'multiple_choice',
  PHOTO_UPLOAD = 'photo_upload',
  OPEN_QUESTION = 'open_question',
  POLL = 'poll',
}

export interface ScreenComponent {
  key: string,
  type: ScreenComponentType,
}

export interface ScreenTitleComponent extends ScreenComponent {
  type: ScreenComponentType.TITLE,
  text: LocalizedValue,
}

export interface ScreenRichTextComponent extends ScreenComponent {
  type: ScreenComponentType.RICH_TEXT,
  richText: LocalizedValue,
}

export interface ScreenImageComponent extends ScreenComponent {
  type: ScreenComponentType.IMAGE,
  media?: ImageMediaItem,
}

export interface ScreenVideoComponent extends ScreenComponent {
  type: ScreenComponentType.VIDEO,
  media?: VideoMediaItem,
}

export interface ScreenQuestionComponent extends ScreenComponent {
  type: ScreenComponentType.QUESTION,
  question: LocalizedValue,
  answer: string,
  points: number,
  hints: QuestionHint[],
  incorrectDeductionPoints: number,
  hintReminderTime: number, //Minutes,
  maxAnswers: number,
  levenshteinDistance: number,
  useSounds: boolean,
  useConfetti: boolean,
}

export interface QuestionHint {
  key: string,
  minutesUntilUnlocked: number,
  pointCost: number,
  title: LocalizedValue,
  text: LocalizedValue,
}

export interface ScreenMultipleChoiceComponent extends ScreenComponent {
  type: ScreenComponentType.MULTIPLE_CHOICE,
  question: LocalizedValue,
  answers: MultipleChoiceAnswer[],
  points: number,
  incorrectDeductionPoints: number,
  maxAnswers: number,
  useSounds: boolean,
  useConfetti: boolean,
}

export interface MultipleChoiceAnswer {
  key: string,
  title: LocalizedValue,
  correct: boolean,
}

export interface ScreenPhotoUploadComponent extends ScreenComponent {
  type: ScreenComponentType.PHOTO_UPLOAD,
  title: LocalizedValue,
  requiredForValidation: boolean, //For screen validation, that is
  moderationRequired: boolean,
  points: number,
}

export interface ScreenOpenQuestionComponent extends ScreenComponent {
  type: ScreenComponentType.OPEN_QUESTION,
  question: LocalizedValue,
  requiredForScreenValidation: boolean,
  showInDashboard: boolean,
  maxCharacters: number | null,
}

export interface ScreenPollComponent extends ScreenComponent {
  type: ScreenComponentType.POLL,
  question: LocalizedValue,
  answers: PollAnswer[],
}

export interface PollAnswer {
  key: string,
  title: LocalizedValue,
}

export type GenericScreenComponent = ScreenTitleComponent | ScreenRichTextComponent | ScreenImageComponent | ScreenVideoComponent | ScreenQuestionComponent | ScreenMultipleChoiceComponent | ScreenPhotoUploadComponent | ScreenOpenQuestionComponent | ScreenPollComponent;