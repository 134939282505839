import { Button, Paper } from "@material-ui/core";
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Header from "../../../common/components/Header";
import { ArrowBackIcon } from "../../../common/components/icons";
import LanguagePicker, { Language, LanguageContext } from "../../../common/components/LanguagePicker";
import Page from "../../../common/components/Page";
import { SnackList } from "../../snack/component/SnackList";
import journeyServices from "../domain/journeyServices";
import { JourneyDetail } from "../domain/models";
import { JourneyDetailNavigation, JourneyNavigationTab } from "./JourneyDetailNavigation";
import { JourneyDetailsEditor } from "./JourneyDetailsEditor";


export function JourneyDetailScreen() {
  const { journeyUuid } = useParams();
  const history = useHistory();
  const [journey, setJourney] = useState<JourneyDetail | undefined>();
  const [selectedTab, setSelectedTab] = useState<JourneyNavigationTab>(JourneyNavigationTab.TAB_CONFIGURATION);
  const [language, setLanguage] = useState<Language>(Language.DUTCH);

  const backToCustomers = () => {
    history.push('/overview');
  }

  const getJourney = useCallback(async () => {
    const journey = await journeyServices.getJourney(journeyUuid);
    if (journey) {
      setLanguage(journey.customer.availableLanguages[0]);
      setJourney(journey);
    }
  }, [journeyUuid]);

  useEffect(() => {
    getJourney();
  }, [getJourney]);

  return <Page>
    <div>
      {
        journey
          ? <>
            <Header title={ journey.title['nl'] } />
            <Paper elevation={ 0 } className="page-detail journey-detail">
              <div className="journey-detail-controls">
                <Button variant="contained" color="primary" startIcon={ <ArrowBackIcon /> } onClick={ (e) => backToCustomers() }>Back to customers</Button>
                <LanguagePicker languages={ journey.customer.availableLanguages } currentLanguage={ language } onChange={ setLanguage } />
              </div>
              <JourneyDetailNavigation selectedTab={ selectedTab } onSelectTab={ (tab) => setSelectedTab(tab) } />
              <LanguageContext.Provider value={ language }>
                <JourneyDetailsEditor journey={ journey } onSaveChanges={ getJourney } />
                <JourneyDetailContext.Provider value={ journey }>
                  <SnackList journeyUuid={ journey.uuid } />
                </JourneyDetailContext.Provider>
              </LanguageContext.Provider>
            </Paper>
          </>
          : <Button variant="contained" color="primary" startIcon={ <ArrowBackIcon /> } onClick={ (e) => backToCustomers() }>Back to customers</Button>
      }
    </div>
  </Page>;
}

export const JourneyDetailContext = createContext<JourneyDetail>({} as JourneyDetail);