import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NextIcon, PreviousIcon } from "../../../common/components/icons";
import { MediaType } from "./MediaDialog";

//Maybe we can strategypattern the media types :thinking_face:
const MediaList = (props) => {
  const { onRequestStateUpdated, onItemSelected, itemsPerPage } = props;
  const mediaItems = props.items;
  const [listOffset, setListOffset] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [typeFilter, setTypeFilter] = useState(props.typeFilter);

  useEffect(() => {
    onItemSelected(null);
    onRequestStateUpdated({
      searchText: searchText === '' ? null : searchText,
      limit: itemsPerPage,
      offset: listOffset,
      type: typeFilter,
    });
  }, [onItemSelected, onRequestStateUpdated, itemsPerPage, listOffset, searchText, typeFilter]);

  const onPreviousClicked = () => {
    setListOffset(Math.max(listOffset - props.itemsPerPage, 0));
  }

  const onNextClicked = () => {
    setListOffset(listOffset + props.itemsPerPage);
  }

  return (
    <div className="media-list-wrapper">
      <div className="media-list">
        { mediaItems != null && mediaItems.map(mediaItem => {
          var className = 'media-item';
          const url = mediaItem.type === 'image' ? mediaItem.url : Object.values(mediaItem.thumbnailUrl)[0];
          const style = { 'backgroundImage': 'url("' + url + '")' };
          if (props.selectedItem != null && props.selectedItem.key === mediaItem.key) className += ' selected';

          return <div className={ className } key={ mediaItem.key } onClick={ (e) => props.onItemSelected(mediaItem) }>
            <div className="media-item-content" style={ style } />
          </div>
        }
        ) }
      </div>
      {
        mediaItems === null && <span>Loading...</span>
      }
      <div className="media-list-controls">
        <IconButton color="primary" onClick={ () => { onPreviousClicked() } } disabled={ listOffset === 0 }><PreviousIcon /></IconButton>
        <IconButton color="primary" onClick={ () => { onNextClicked() } } disabled={ mediaItems && mediaItems.length < props.itemsPerPage }><NextIcon /></IconButton>
        <TextField placeholder={ 'Search in tags' } value={ searchText } onChange={ (e) => setSearchText(e.target.value) } />
        <FormControl style={ { width: '150px' } } variant="outlined" size="small" disabled>
          <InputLabel id="type-filter-label">Type</InputLabel>
          <Select
            labelId="type-filter-label"
            value={ typeFilter }
            label="Type"
            onChange={ (e) => setTypeFilter(e.target.value) }
          >
            <MenuItem value={ '' }>All</MenuItem>
            <MenuItem value={ MediaType.IMAGE }>Image</MenuItem>
            <MenuItem value={ MediaType.VIDEO }>Video</MenuItem>
          </Select>
        </FormControl>
      </div>

    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);