import { Button, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Header from "../../../common/components/Header";
import Page from "../../../common/components/Page";
import EventList from "../../event/component/EventList";
import { fetchCurrentChallenge } from "../redux/challengeOperations";
import { useEffect, useState } from "react";
import * as eventActions from "../../event/redux/eventSlice";
import TeamContainer from "../../team/component/TeamContainer";
import ChallengeDetailNavigation, { CHALLENGE_NAVIGATION } from "./ChallengeDetailNavigation";
import { PhotoUploadList } from "../../photo_upload/component/PhotoUploadList";
import { setCurrentChallenge } from "../redux/challengeSlice";
import ChallengeProgressView from "./ChallengeProgressView";


const ChallengeDetailScreen = (props) => {
  const { challengeUuid } = useParams();
  const fetchCurrentChallenge = props.fetchCurrentChallenge;
  const [selectedTab, setSelectedTab] = useState(CHALLENGE_NAVIGATION.TAB_CONFIGURATION);
  const challenge = props.currentChallenge;
  const history = useHistory();

  useEffect(() => {
    fetchCurrentChallenge(challengeUuid);
  }, [fetchCurrentChallenge, challengeUuid]);

  const backToOverview = () => {
    props.clearCurrentEventList();
    props.clearCurrentChallenge();
    history.replace('/overview');
  }

  return (
    <Page>
      { challenge &&
        <div>
          <Header title={ challenge.title } />
          <Paper elevation={ 0 } className="page-detail">
            <Button variant="contained" color="primary" startIcon={ <ArrowBack /> } onClick={ (e) => backToOverview() }>Back to overview</Button>
            <ChallengeDetailNavigation selectedTab={ selectedTab } onSelectTab={ setSelectedTab } />
            {
              selectedTab === CHALLENGE_NAVIGATION.TAB_CONFIGURATION
              && <>
                <TeamContainer challengeUuid={ challengeUuid } />
                <EventList challengeUuid={ challengeUuid } />
              </>
            }
            {
              selectedTab === CHALLENGE_NAVIGATION.TAB_PROGRESS
              && <>
                <ChallengeProgressView challenge={ challenge } />
              </>
            }
            {
              selectedTab === CHALLENGE_NAVIGATION.TAB_PHOTOS
              && <PhotoUploadList challengeUuid={ challengeUuid } />
            }
          </Paper>
        </div>
      }
    </Page>
  );
}

const mapStateToProps = (state, ownProps) => ({
  currentChallenge: state.challenge.currentChallenge,
});

const mapDispatchToProps = (dispatch) => ({
  clearCurrentEventList: () => dispatch(eventActions.clearCurrent()),
  fetchCurrentChallenge: (challengeUuid) => dispatch(fetchCurrentChallenge(challengeUuid)),
  clearCurrentChallenge: () => dispatch(setCurrentChallenge(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetailScreen);

