import { EventUpdateRepository } from "../data/EventUpdateRepository"

const eventUpdateRepository = new EventUpdateRepository();

export const getList = async (challengeUuid) => {
  const updates = await eventUpdateRepository.fetchList(challengeUuid);

  return updates;
}

export const getDistinctEvents = (eventUpdates) => {
  let distinctEvents = [];

  eventUpdates.forEach(eventUpdate => {
    const eventInList = distinctEvents.find((event) => eventUpdate.event.uuid === event.uuid);
    if (eventInList === undefined) {
      distinctEvents.push(eventUpdate.event);
    }
  });

  return distinctEvents;
}


export const getDistinctTeams = (eventUpdates) => {
  let distinctTeams = [];

  eventUpdates.forEach(eventUpdate => {
    const teamInList = distinctTeams.find((team) => eventUpdate.team.uuid === team.uuid);
    if (teamInList === undefined) {
      distinctTeams.push(eventUpdate.team);
    }
  });

  return distinctTeams;
}