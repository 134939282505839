import { useCallback, useEffect, useState } from "react";
import EventUpdateList from "../../event_update/component/EventUpdateList";
import TeamScores from "../../team/component/TeamScores";
import { ChallengeDetail } from "../domain/models";
import challengeServices from "../domain/services";

interface ChallengeProgressViewProps {
  challenge: ChallengeDetail,
}

const ChallengeProgressView = ({ challenge }: ChallengeProgressViewProps) => {

  return <>
    <ChallengeShareLink challenge={ challenge } />
    <TeamScores challengeUuid={ challenge.uuid } />
    <EventUpdateList challenge={ challenge } />
  </>
}

export default ChallengeProgressView;

interface ChallengeShareLinkProps {
  challenge: ChallengeDetail,
}

const ChallengeShareLink = ({ challenge }: ChallengeShareLinkProps) => {
  const [shareLink, setShareLink] = useState<string | null>(null);
  const [errorHappened, setErrorHappened] = useState<boolean>(false);

  const fetchShareLink = useCallback(async () => {
    try {
      const generatedLink = await challengeServices.generateShareUrl(challenge.uuid);
      setShareLink(generatedLink);
    } catch (e) {
      setErrorHappened(true);
    }
  }, [challenge]);

  useEffect(() => {
    fetchShareLink();
  }, [fetchShareLink]);

  return <>
    <h3>Share link</h3>
    { shareLink }
    { errorHappened ? 'Something went wrong' : '' }
  </>
}