import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    list: [],
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
  }
});

export const {
  setList,
} = customerSlice.actions;

export default customerSlice.reducer;