import { Button, Checkbox, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import { useState } from "react";
import { ScreenOpenQuestionComponent } from "../domain/models";
import { DefaultDialogTransition } from "../../../helpers/ui";

interface OpenQuestionSettingsDialogProps {
  component: ScreenOpenQuestionComponent,
  onClose: () => void,
  onSave: (component: ScreenOpenQuestionComponent) => void,
}

export const OpenQuestionSettingsDialog = (props: OpenQuestionSettingsDialogProps) => {
  const component = props.component;
  const [requiredForScreenValidation, setRequiredForScreenValidation] = useState<boolean>(component.requiredForScreenValidation);
  const [showInDashboard, setShowInDashboard] = useState<boolean>(component.showInDashboard);
  const [allowMaxCharacters, setAllowMaxCharacters] = useState<boolean>(component.maxCharacters != null);
  const [maxCharacters, setMaxCharacters] = useState<number | null>(component.maxCharacters);

  const onConfirm = () => {
    const updatedComponent: ScreenOpenQuestionComponent = {
      ...component,
      requiredForScreenValidation: requiredForScreenValidation,
      showInDashboard: showInDashboard,
      maxCharacters: maxCharacters,
    }

    props.onSave(updatedComponent);
    props.onClose();
  }

  return (
    <Dialog open={ true } TransitionComponent={ DefaultDialogTransition } maxWidth="lg" onClose={ (e) => { props.onClose() } }>
      <DialogContent>
        <div className="photo-upload-dialog dialog-content">
          <div className="settings-header">
            <h2>Photo upload settings</h2>
          </div>
          <div className="settings-content">
            <div>
              <span>Answer required to continue in screen:</span>
              <Checkbox
                checked={ requiredForScreenValidation }
                onChange={ (e) => { setRequiredForScreenValidation(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div>
              <span>Show answers in dashboard:</span>
              <Checkbox
                checked={ showInDashboard }
                onChange={ (e) => { setShowInDashboard(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div>
              <span>Max characters:</span>
              <Checkbox
                checked={ allowMaxCharacters }
                onChange={ (e) => {
                  const checked = e.target.checked;
                  setMaxCharacters(checked ? 256 : null);
                  setAllowMaxCharacters(checked);
                } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
              { allowMaxCharacters && <TextField variant="filled" label="Max characters" value={ maxCharacters } type="number" onChange={ (e) => setMaxCharacters(parseInt(e.target.value)) } size="small" /> }
            </div>
            <div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => props.onClose() }>
          Cancel
        </Button>
        <Button onClick={ (e) => onConfirm() }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}


