import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponentContainer from "./ScreenComponentContainer";
import { IconButton } from "@material-ui/core";
import MediaDialog, { MediaType } from "../../media/component/MediaDialog";

export const ScreenVideoComponentConfigurator = (props) => {
  const component = props.component;
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const handleEditButtonPressed = () => {
    setMediaDialogOpen(true);
  }

  const handleMediaSelected = (media) => {
    props.onSave({
      ...component,
      media: { key: media.key },
    });
  }

  const thumbnailUrl = component.media?.thumbnailUrl ? component.media.thumbnailUrl[props.language] : null;

  return (
    <ScreenComponentContainer className="screen-image-component" component={ props.component } onDelete={ props.onDelete } type="Video">
      <div className="screen-image-component-content">
        <img className="component-image" src={ thumbnailUrl ? thumbnailUrl : '' } alt={ component.media ? "Video thumbnail not existent in current language" : "No video selected yet" } />
        <IconButton component="span" onClick={ (e) => handleEditButtonPressed() }>
          <EditIcon />
        </IconButton>
      </div>
      { mediaDialogOpen && <MediaDialog onClose={ () => setMediaDialogOpen(false) } onConfirm={ handleMediaSelected } selectionType={ MediaType.VIDEO } /> }
    </ScreenComponentContainer>
  );
}
