import ApplicationConfig from "../../../ApplicationConfig";
import { ApiError } from "../../../common/domain/models";
import { getDefaultHeaders } from "../../../helpers/apiHelpers";
import { ChallengeShareData } from "../domain/models";

export const CHALLENGE_SHARE_TOKEN_HEADER = 'x-challenge-share-token';

export class ShareRepository {

  async getChallengeShareData(shareToken: string): Promise<ChallengeShareData> {
    const response = await fetch(ApplicationConfig.BACKEND_URL + '/share/challenge-data', {
      headers: {
        ...getDefaultHeaders(),
        [CHALLENGE_SHARE_TOKEN_HEADER]: shareToken,
      },
    });

    if (response && response.status === 200) {
      const responseBody = await response.json();

      return responseBody;
    }

    throw new ApiError('Error fetching challenge share data', response);
  }

  getDownloadChallengePhotosUrl(shareToken: string): string {
    return ApplicationConfig.BACKEND_URL + '/share/download-challenge-photos?share_token=' + shareToken;
  }
}