import { Button, FormGroup, TextField, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, capitalize } from "@material-ui/core";
import { HexColorPicker } from "react-colorful";
import { useEffect, useState } from "react";

import { Customer, CustomerRegistrationType, CustomerType } from "../data/models";
import { Language } from "../../../common/components/LanguagePicker";

interface CustomerFormProps {
  initialData: Customer,
  onDataEdited: (data: any) => void; //TODO: this model should be a dto
}

const CustomerForm = (props: CustomerFormProps) => {
  const customer = props.initialData;
  const onDataEdited = props.onDataEdited;
  const [title, setTitle] = useState(customer ? customer.title : '');
  const [contactEmail, setContactEmail] = useState(customer ? customer.contactEmail : '');
  const [type, setType] = useState(customer ? customer.type : CustomerType.CHALLENGE);
  const [accessCode, setAccessCode] = useState(customer ? customer.accessCode : '');
  const [languages, setLanguages] = useState(customer ? customer.availableLanguages : [Language.DUTCH]);
  const [registrationType, setRegistrationType] = useState<CustomerRegistrationType>(customer ? customer.registrationType : CustomerRegistrationType.PLAYER_SELECT);
  const [registerRequiresEmail, setRegisterRequiresEmail] = useState<boolean>(customer ? customer.registerRequiresEmail : false);
  const [isSinglePlayer, setIsSinglePlayer] = useState<boolean>(customer ? customer.isSinglePlayer : false);
  const [invoicePerRegistration, setInvoicePerRegistration] = useState<boolean>(customer ? customer.invoicePerRegistration : false);
  const [primaryColor, setPrimaryColor] = useState(customer ? customer.colors.primaryColor : '#02989f');
  const [selectectedLogoDataUrl, setSelectedLogoDataUrl] = useState<string | null>(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  useEffect(() => {
    onDataEdited({
      title: title,
      contactEmail: contactEmail,
      type: type,
      accessCode: accessCode,
      colors: {
        primaryColor: primaryColor,
      },
      logoBase64: selectectedLogoDataUrl,
      availableLanguages: languages,
      registrationType: registrationType,
      registerRequiresEmail: registerRequiresEmail,
      isSinglePlayer: isSinglePlayer,
      invoicePerRegistration: invoicePerRegistration,
    });
  }, [onDataEdited, title, contactEmail, type, accessCode, languages, primaryColor, selectectedLogoDataUrl, registrationType, registerRequiresEmail, isSinglePlayer, invoicePerRegistration]);

  const onFileSelected = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFileName(selectedFile.name);

    var reader = new FileReader();
    reader.onload = function () {
      setSelectedLogoDataUrl(reader.result as string);
    }
    reader.readAsDataURL(selectedFile);
  }

  //TODO: abstract colorpicker
  return (
    <div className='customer-form'>
      <div className="form-image">
        { selectectedLogoDataUrl
          ? <img alt="customer logo" src={ selectectedLogoDataUrl } />
          : (customer && customer.logoUrl) && <img alt="customer logo" src={ customer.logoUrl } />
        }
        <div className="form-image-controls">
          <Button variant="contained" component="label">Select logo<input type="file" onChange={ onFileSelected } hidden /></Button>
          { selectedFileName && <p>{ selectedFileName }</p> }
        </div>
      </div>
      <TextField className="form-textfield" fullWidth={ true } onChange={ (e) => setTitle(e.target.value) } label="Title" variant="filled" value={ title } />
      <TextField className="form-textfield" fullWidth={ true } onChange={ (e) => setContactEmail(e.target.value) } label="Contact email" variant="filled" value={ contactEmail } />
      <TypePicker currentType={ type } onChange={ setType } />
      <TextField className="form-textfield" fullWidth={ true } onChange={ (e) => setAccessCode(e.target.value) } label="Access code" variant="filled" value={ accessCode } />
      <LanguageSelector languages={ languages } onChange={ setLanguages } />
      <TextField className="form-textfield" fullWidth={ true } onChange={ (e) => setPrimaryColor(e.target.value) } label="Primary color" variant="filled" value={ primaryColor } />
      <div className="form-color-picker">
        <HexColorPicker color={ primaryColor } onChange={ setPrimaryColor } />
        <div className="form-color-picker-color" style={ { backgroundColor: primaryColor } }></div>
      </div>
      <RegistrationInfoControl
        registrationType={ registrationType }
        registerRequiresEmail={ registerRequiresEmail }
        isSinglePlayer={ isSinglePlayer }
        invoicePerRegistration={ invoicePerRegistration }
        onUpdateRegistrationType={ setRegistrationType }
        onUpdateRequiresEmail={ setRegisterRequiresEmail }
        onUpdateIsSinglePlayer={ setIsSinglePlayer }
        onUpdateInvoicePerRegistration={ setInvoicePerRegistration }
      />
    </div>
  )
}

export default CustomerForm;

const LanguageSelector = (props) => {
  const onChange = props.onChange;
  const [selectedLanguages, setSelectedLanguages] = useState(props.languages);

  useEffect(() => {
    onChange(selectedLanguages);
  }, [onChange, selectedLanguages]);

  const isSelected = (language) => {
    return selectedLanguages.includes(language);
  };

  const handleChange = (e) => {
    const selectedLanguage = e.target.name;
    const checked = e.target.checked;
    if (checked) {
      setSelectedLanguages([...selectedLanguages, selectedLanguage]);
    } else {
      setSelectedLanguages(selectedLanguages.filter((language) => language !== selectedLanguage));
    }
  };

  return (
    <div className="form-language-picker">
      <p>Languages:</p>
      <FormGroup row>
        <FormControlLabel label="nl" control={ <Checkbox color="primary" checked={ isSelected("nl") } name="nl" onChange={ handleChange } /> } />
        <FormControlLabel label="fr" control={ <Checkbox color="primary" checked={ isSelected("fr") } name="fr" onChange={ handleChange } /> } />
        <FormControlLabel label="en" control={ <Checkbox color="primary" checked={ isSelected("en") } name="en" onChange={ handleChange } /> } />
      </FormGroup>
    </div>
  );
}


interface TypePickerProps {
  currentType: CustomerType;
  onChange: (type: CustomerType) => void;
}

function TypePicker({ currentType, onChange }: TypePickerProps) {
  return (
    <FormControl className="customer-type-picker" variant="filled">
      <InputLabel>Type</InputLabel>
      <Select value={ currentType } onChange={ (e) => onChange(e.target.value as CustomerType) }>
        { Object.values(CustomerType).map((type) => {
          return (
            <MenuItem key={ type } value={ type }>{ capitalize(type) }</MenuItem>
          )
        }) }
      </Select>
    </FormControl>
  );
}

interface RegistrationInfoControlProps {
  registrationType: CustomerRegistrationType,
  registerRequiresEmail: boolean,
  isSinglePlayer: boolean,
  invoicePerRegistration: boolean,
  onUpdateRegistrationType: (type: CustomerRegistrationType) => void,
  onUpdateRequiresEmail: (value: boolean) => void,
  onUpdateIsSinglePlayer: (value: boolean) => void,
  onUpdateInvoicePerRegistration: (value: boolean) => void,
}

function RegistrationInfoControl(props: RegistrationInfoControlProps) {
  const { registrationType, registerRequiresEmail, isSinglePlayer, invoicePerRegistration } = props;

  const handleTypeChange = (type: CustomerRegistrationType) => {
    if (type === CustomerRegistrationType.PLAYER_SELECT) {
      props.onUpdateRegistrationType(type);
      props.onUpdateRequiresEmail(false);
      props.onUpdateIsSinglePlayer(false);
      props.onUpdateInvoicePerRegistration(false);
    } else {
      props.onUpdateRegistrationType(type);
    }
  }

  return (
    <div>
      <FormControl className="customer-registration-type-picker" variant="filled">
        <InputLabel>Registration type</InputLabel>
        <Select value={ registrationType } onChange={ (e) => handleTypeChange(e.target.value as CustomerRegistrationType) }>
          { Object.values(CustomerRegistrationType).map((type) => {
            return (
              <MenuItem key={ type } value={ type }>{ capitalize(type) }</MenuItem>
            )
          }) }
        </Select>
      </FormControl>
      {
        registrationType !== CustomerRegistrationType.PLAYER_SELECT
          ? <>
            <div>
              <span>E-mail required:</span>
              <Checkbox
                checked={ registerRequiresEmail }
                onChange={ (e) => { props.onUpdateRequiresEmail(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div>
              <span>Single player:</span>
              <Checkbox
                checked={ isSinglePlayer }
                onChange={ (e) => { props.onUpdateIsSinglePlayer(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div>
              <span>Invoice per registration:</span>
              <Checkbox
                checked={ invoicePerRegistration }
                onChange={ (e) => { props.onUpdateInvoicePerRegistration(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
          </>
          : <></>
      }
    </div>
  );
}

