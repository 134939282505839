import { Checkbox, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

interface EventDurationControlProps {
  duration: number | null,
  onUpdate: (duration: number | null) => void,
}

const EventDurationControl = (props: EventDurationControlProps) => {
  const handleCheckBoxChecked = (checked) => {
    props.onUpdate(checked ? 90 : null);
  }

  const handleEditDurationClicked = () => {
    const durationString = prompt('Select event duration', '' + props.duration);
    if (durationString) {
      const duration = parseInt(durationString!);
      if (duration) {
        props.onUpdate(duration);
      }
    }
  }

  return (
    <div className='event-duration-control'>
      <label><strong>Timed:</strong></label>
      <Checkbox
        checked={props.duration != null}
        onChange={(e) => handleCheckBoxChecked(e.target.checked)}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {props.duration &&
        <>
          <label>{props.duration} min</label>
          <IconButton onClick={(e) => handleEditDurationClicked()}><EditIcon /></IconButton>
        </>
      }
    </div>
  )
}

export default EventDurationControl;