import { PhotoUpload } from "../../photo_upload/domain/models";
import { Team, TeamMember } from "../../team/domain/models";
import { ShareRepository } from "../data/ShareRepository";
import { ChallengeShareData, TeamPhotoUploads } from "./models";

const shareRepository = new ShareRepository();

const getChallengeShareData = async (shareToken: string): Promise<ChallengeShareData> => {
  return shareRepository.getChallengeShareData(shareToken);
}

const getChallengePhotoDownloadUrl = (shareToken: string): string => {
  return shareRepository.getDownloadChallengePhotosUrl(shareToken);
}

const sortPhotoUploadsPerTeam = (photoUploads: PhotoUpload[], teams: Team[]): TeamPhotoUploads[] => {
  const photoUploadsPerTeam: TeamPhotoUploads[] = [];

  photoUploads.forEach((upload) => {
    const existingItem = photoUploadsPerTeam.find((item) => item.team.uuid === upload.team.uuid);
    if (existingItem) {
      existingItem.photoUploads.push(upload);
    } else {
      photoUploadsPerTeam.push({
        team: teams.find((fullTeam) => fullTeam.uuid === upload.team.uuid)!,
        photoUploads: [upload],
      });
    }
  })

  return photoUploadsPerTeam;
}

const getTeamMembersString = (members: TeamMember[]) => {
  return members.map((member) => member.name).join(', ');
}

const shareServices = {
  getChallengeShareData,
  getChallengePhotoDownloadUrl,
  sortPhotoUploadsPerTeam,
  getTeamMembersString,
};

export default shareServices;