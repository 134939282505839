import { Button, Paper } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { AddIcon } from "../../../common/components/icons";
import { Language } from "../../../common/components/LanguagePicker";
import journeyServices from "../domain/journeyServices";
import { Journey } from "../domain/models";
import { JourneyListItem } from "./JourneyListItem";

interface JourneyListProps {
  customerUuid: string,
}

export function JourneyList({ customerUuid }: JourneyListProps) {
  const [list, setList] = useState<Journey[]>([]);

  const refreshJourneys = useCallback(async () => {
    const journeys = await journeyServices.getList(customerUuid);
    setList(journeys);
  }, [customerUuid]);

  useEffect(() => {
    refreshJourneys();
  }, [refreshJourneys])

  const activateJourney = useCallback(async (journey: Journey) => {
    await journeyServices.activateJourney(customerUuid, journey.uuid);
    await refreshJourneys();
  }, [customerUuid, refreshJourneys]);

  const deleteJourney = useCallback(async (journey: Journey) => {
    await journeyServices.deleteJourney(journey.uuid);
    await refreshJourneys();
  }, [refreshJourneys]);

  const handleCreateJourneyClicked = useCallback(async () => {
    const dutchTitle = prompt('Dutch title:');
    if (dutchTitle && dutchTitle.length > 0) {
      const title = { [Language.DUTCH]: dutchTitle };
      const superTitle = { [Language.DUTCH]: 'Tekst boven ' + dutchTitle };
      await journeyServices.createJourney(customerUuid, title, superTitle);
      await refreshJourneys();
    }
  }, [customerUuid, refreshJourneys]);

  return (
    <div>
      <Paper elevation={ 0 } className="journey-list">
        <h3>Journeys:</h3>
        { list.map((journey) => {
          return <JourneyListItem
            key={ journey.uuid }
            journey={ journey }
            onActivate={ () => { activateJourney(journey) } }
            onDelete={ () => { deleteJourney(journey) } }
          />;
        }) }
        <div className="journey-list-controls">
          <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ handleCreateJourneyClicked }>Create journey</Button>
        </div>
      </Paper>
    </div>
  )
}