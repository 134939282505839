import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";
import { Event } from "./models";

export class EventRepository {

    async fetchList(challengeUuid): Promise<any[]> {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/list?challenge_uuid=' + challengeUuid,
            {}
        );

        const responseBody = await response!.json();

        return responseBody;
    }

    async createEvent(challengeUuid: string, event: Event) {
        const postBody = {
            challengeUuid: challengeUuid,
            ...event,
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/create',
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response!.json();
        return responseBody;
    }

    async editEvent(event: Event) {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/edit',
            {
                method: 'POST',
                body: JSON.stringify(event),
            }
        );

        const responseBody = await response!.json();
        return responseBody;
    }

    async deleteEvent(event: Event) {
        const postBody = {
            uuid: event.uuid
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/delete',
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response!.json();
        return responseBody;
    }

    async resetEvent(event: Event) {
        const postBody = {
            uuid: event.uuid
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/reset',
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response!.json();
        return responseBody;
    }

}

