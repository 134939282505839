
import { IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import ScreenComponentContainer from "./ScreenComponentContainer";
import { EditIcon, SettingsIcon } from "../../../common/components/icons";
import PhotoUploadSettingsDialog from "./PhotoUploadSettingsDialog";

export const ScreenPhotoUploadComponentConfigurator = (props) => {
  const component = props.component;
  const language = props.language;
  const [title, setTitle] = useState('');
  const [isEditing, setIsEditing] = useState(component.title ? false : true);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  useEffect(() => {
    setTitle(component.title ? (component.title[language] ? component.title[language] : '') : '');
  }, [language, component.title]);

  const onConfirm = () => {
    var updatedTitle = {
      ...(component.title ? component.title : {})
    };
    updatedTitle[language] = title;

    //TODO: provide default values - maybe a screen component model factory?
    props.onSave({
      ...component,
      title: updatedTitle,
    });

    setIsEditing(false);
  }

  const openSettings = () => {
    setIsSettingsDialogOpen(true);
  }

  const onSaveSettings = (component) => {
    props.onSave(component);
  }

  return (
    <ScreenComponentContainer className="screen-photo-upload-component" component={ props.component } onDelete={ props.onDelete } type="Photo upload">
      { isEditing
        ? <TextField variant="outlined" label="Text" value={ title } onChange={ (e) => setTitle(e.target.value) } onKeyPress={ (e) => { if (e.key === 'Enter') onConfirm() } } autoFocus />
        : <div className="screen-photo-upload-component-content">
          <p>Title: { title }</p>
          <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
          { component.title != null && <IconButton onClick={ (e) => openSettings() }><SettingsIcon /></IconButton> }
        </div>
      }
      { isSettingsDialogOpen && <PhotoUploadSettingsDialog component={ component } onClose={ () => setIsSettingsDialogOpen(false) } onSave={ onSaveSettings } languages={ props.languages } /> }
    </ScreenComponentContainer>
  )

}
