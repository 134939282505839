import { store } from '../state/store';
import { logout } from '../features/user/redux/userOperations';

export const getDefaultHeaders = () => {
    return {
        'Content-Type': 'application/json',
    }
}

export const getAuthorizedDefaultHeaders = () => {
    return {
        ...getDefaultHeaders(),
        'Authorization': 'Bearer ' + getTokenFromState()
    };
}

export const authenticatedFetch = async (url, options) => {
    const response = await fetch(url, {
        ...options,
        headers: {
            ...getAuthorizedDefaultHeaders(),
            ...options.headers
        }
    });

    if (response.status === 403) {
        store.dispatch(logout());
    } else {
        return response;
    }
}

export const authenticatedJsonPost = async (url, body) => {
    const response = await fetch(url, {
        headers: getAuthorizedDefaultHeaders(),
        method: 'POST',
        body: JSON.stringify(body),
    });

    if (response.status === 403) {
        store.dispatch(logout());
    } else {
        return response;
    }
}

function getTokenFromState() {
    return store.getState().user.auth.token;
}


