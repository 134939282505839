import { Snack } from "../domain/models";
import * as apiHelper from "../../../helpers/apiHelpers";
import ApplicationConfig from "../../../ApplicationConfig";
import { ApiError } from "../../../common/domain/models";
import { CreateSnackData, EditSnackData } from "./models";

export class SnackRepository {

  async getListByJourneyUuid(journeyUuid: string): Promise<Snack[]> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack/list?journey_uuid=' + journeyUuid,
      {}
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      //TODO: parse zod
      return responseBody;
    }

    throw new ApiError('Error fetching journey list', response);
  }

  async create(data: CreateSnackData): Promise<void> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack/create',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (response && response.status === 200) {
      return;
    }

    throw new ApiError('Error creating snack', response);
  }

  async edit(data: EditSnackData): Promise<void> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack/edit',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (response && response.status === 200) {
      return;
    }

    throw new ApiError('Error editing snack', response);
  }

  async delete(uuid: string): Promise<void> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack/delete',
      {
        method: 'POST',
        body: JSON.stringify({ uuid: uuid }),
      }
    );

    if (response && response.status === 200) {
      return;
    }

    throw new ApiError('Error deleting snack', response);
  }

}