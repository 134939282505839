import ApplicationConfig from "../../../ApplicationConfig";
import { ApiError } from "../../../common/domain/models";
import * as apiHelper from "../../../helpers/apiHelpers";
import { CHALLENGE_SHARE_TOKEN_HEADER } from "../../share/data/ShareRepository";

export class PhotoUploadRepository {

  async getList(challengeUuid) {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/photo-upload/list?challenge_uuid=' + challengeUuid,
      {}
    );

    if (response) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      throw new ApiError('invalid response getting photo list');
    }

  }

  async moderateUpload(eventUpdateId, approved, rejectionReason) {
    const postBody = {
      eventUpdateId: eventUpdateId,
      approved: approved,
      rejectionReason: rejectionReason,
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/photo-upload/moderate',
      {
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    if (response) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      throw new ApiError('invalid response moderating upload');
    }
  }

  async getPrivateImageBlobUrl(imageUrl: string, shareToken: string | undefined) {
    let response: Response | undefined = undefined;
    //If we have a sharetoken, we try to download it that way
    //Otherwise, we assume the user is logged in and just use the authenticatedFetch
    //If we would always use authenticatedFetch, the "Authorization" header says "Bearer null" which is confusing
    if (shareToken) {
      response = await fetch(
        imageUrl,
        {
          headers: {
            [CHALLENGE_SHARE_TOKEN_HEADER]: shareToken
          }
        }
      );
    } else {
      response = await apiHelper.authenticatedFetch(imageUrl, {});
    }


    if (response) {
      const responseBody = await response.blob();
      return URL.createObjectURL(responseBody);
    } else {
      throw new ApiError('invalid response on fetching private image');
    }
  }


}