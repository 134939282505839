import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class EventUpdateRepository {

    async fetchList(challengeUuid) {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event-update/list?challenge_uuid=' + challengeUuid,
            {}
        );

        const responseBody = await response.json();
        return responseBody;
    }

}