import { Button, ButtonGroup } from "@material-ui/core";

export const CHALLENGE_NAVIGATION = {
  TAB_CONFIGURATION: 'tab_configuration',
  TAB_PROGRESS: 'tab_progress',
  TAB_PHOTOS: 'tab_photos',
}

const ChallengeDetailNavigation = (props) => {

  const titleForTab = (tab) => {
    switch (tab) {
      case CHALLENGE_NAVIGATION.TAB_CONFIGURATION:
        return 'Edit configuration';
      case CHALLENGE_NAVIGATION.TAB_PROGRESS:
        return 'View progress';
      case CHALLENGE_NAVIGATION.TAB_PHOTOS:
        return 'Photo uploads';
      default:
        break;
    }
  }

  return (
    <div className="challenge-navigation">
      <ButtonGroup variant="outlined" aria-label="outlined primary button group">
        {
          Object.values(CHALLENGE_NAVIGATION).map((tab) => {
            const isSelected = tab === props.selectedTab;
            return <Button
              key={ tab }
              onClick={ (e) => props.onSelectTab(tab) }
              color={ isSelected ? 'primary' : null }
              variant={ isSelected ? 'contained' : 'outlined' }
            >
              { titleForTab(tab) }
            </Button>
          })
        }
      </ButtonGroup>
    </div>
  );
}

export default ChallengeDetailNavigation;
