import { Button, Checkbox, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useState } from "react";
import { DefaultDialogTransition } from "../../../helpers/ui";
import { Event, EventTimings } from "../data/models";
import EventDurationControl from "./EventDurationControl";
import "react-datetime/css/react-datetime.css";
import { DateTimePicker } from "../../../common/components/DateTimePicker";


interface EventSettingsDialogProps {
  event: Event,
  onSave: (duration: number | null, timings: EventTimings | null, emailPlayerAfterCompletion: boolean,) => void,
  onCancel: () => void,
}

export function EventSettingsDialog(props: EventSettingsDialogProps) {
  const { event, onSave, onCancel } = props;
  const [duration, setDuration] = useState<number | null>(event.duration);
  const [hasTimings, setHasTimings] = useState<boolean>(event.timings != null);
  const [timings, setTimings] = useState<EventTimings | null>(event.timings);
  const [emailPlayerAfterCompletion, setEmailPlayerAfterCompletion] = useState<boolean>(event.emailPlayerAfterCompletion);

  const handleTimingsCheckboxChecked = (checked: boolean) => {
    if (checked) {
      setTimings({
        activeFrom: new Date(),
        activeUntil: new Date(),
        visibleFrom: new Date(),
        visibleUntil: new Date(),
      })
    } else {
      setTimings(null);
    }

    setHasTimings(checked);
  }

  const handleSaveClicked = () => {
    onSave(duration, timings, emailPlayerAfterCompletion);
  }

  return (
    <Dialog open={ true } TransitionComponent={ DefaultDialogTransition } onClose={ (e) => { } } maxWidth={ hasTimings ? 'lg' : 'sm' } fullWidth={ true }>
      <DialogContent className="event-settings-dialog">
        <EventDurationControl duration={ duration } onUpdate={ setDuration } />
        <label><strong>Email player after they ended this event:</strong></label>
        <Checkbox
          checked={ emailPlayerAfterCompletion }
          onChange={ (e) => setEmailPlayerAfterCompletion(e.target.checked) }
          color="primary"
          inputProps={ { 'aria-label': 'primary checkbox' } }
        /><br />
        <label><strong>Use dates:</strong></label>
        <Checkbox
          checked={ hasTimings }
          onChange={ (e) => handleTimingsCheckboxChecked(e.target.checked) }
          color="primary"
          inputProps={ { 'aria-label': 'primary checkbox' } }
        />
        {
          (hasTimings && timings) && <div className="event-settings-timings">
            <DateTimePicker label='Active from' value={ timings.activeFrom } onChange={ (date) => setTimings({ ...timings, activeFrom: date }) } />
            <DateTimePicker label='Active until' value={ timings.activeUntil } onChange={ (date) => setTimings({ ...timings, activeUntil: date }) } />
            <DateTimePicker label='Visible from' value={ timings.visibleFrom } onChange={ (date) => setTimings({ ...timings, visibleFrom: date }) } />
            <DateTimePicker label='Visible until' value={ timings.visibleUntil } onChange={ (date) => setTimings({ ...timings, visibleUntil: date }) } />
          </div>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => { onCancel() } }>
          Close
        </Button>
        <Button onClick={ (e) => { handleSaveClicked() } }>
          Save
        </Button>
      </DialogActions>
    </Dialog>);
}

