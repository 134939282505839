import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ScreenRepository } from "../data/ScreenRepository";

import { AddIcon, DeleteIcon, EditIcon, RemoveIcon } from "../../../common/components/icons";
import { extraConfirm } from "../../../util/promptUtil";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { ScreenComponentRenderer } from "../../screen_component/component/ScreenComponentRenderer";
import screenComponentServices from "../../screen_component/domain/screenComponentServices";
import { Screen } from "../domain/models";
import { Language, LanguageContext } from "../../../common/components/LanguagePicker";
import { ScreenComponentType } from "../../screen_component/domain/models";

interface ScreenListItemProps {
  screen?: Screen,
  availableLanguages: Language[];
  onUpdate?: () => void,
  onConfirmCreate?: (title: string, type: string) => void,
}

const ScreenListItem = (props: ScreenListItemProps) => {
  const screen = props.screen;
  const language = useContext(LanguageContext);
  const [isOpened, setIsOpened] = useState(false);
  const [components, setComponents] = useState(screen ? screen.data.components : []);
  const [isEditingTitle, setIsEditingTitle] = useState(screen ? false : true);
  const [title, setTitle] = useState(screen ? screen.title : '');

  useEffect(() => {
    if (screen) {
      setTitle(screen.title);
      setComponents(screen.data.components);
    }

  }, [screen]);

  const onConfirmTitle = () => {
    setIsEditingTitle(false);
    if (screen) {
      updateScreen(title, components);
    } else {
      props.onConfirmCreate!(title, 'content');
    }
  }


  const onDeleteScreen = () => {
    if (extraConfirm('Are you sure? This will erase the screen, its components and all related player data.')) {
      deleteScreen();
    }
  }

  const onAddComponent = (componentType: ScreenComponentType) => {
    const newComponent = screenComponentServices.createDefaultComponentForType(componentType);

    setComponents([...components, newComponent]);
  }

  //TODO: move this to screen operations
  //TODO: show notification on save
  const onSaveComponent = (componentToUpdate) => {
    var updatedComponents = components.map((component) => {
      if (component.key === componentToUpdate.key) {
        return componentToUpdate;
      } else {
        return component;
      }
    });

    updateScreen(title, updatedComponents);
  }

  //TODO: move this to screen operations
  const onDeleteComponent = (componentToDelete) => {
    const updatedComponents = components.filter((component) => {
      return component.key !== componentToDelete.key;
    });

    updateScreen(title, updatedComponents);
  }

  //TODO: move this to screen operations
  //TODO: there's a potential issue here where media components' data in the db should be stored with just a reference to the media
  //But due to completely copying the rest of the components, the data to just display the media is also stored in the db
  //Potential fix: create a getMedia call in the backend, and then the API doesn't have to return all the display data in the initial call.
  const updateScreen = (title, updatedComponents) => {
    const screenRepository = new ScreenRepository();
    screenRepository.editScreen({
      ...screen,
      title: title,
      data: {
        components: updatedComponents,
      },
    }).then((result) => {
      props.onUpdate!();
    });
  }

  //TODO: move this to screen operations
  const deleteScreen = async () => {
    const screenRepository = new ScreenRepository();
    await screenRepository.deleteScreen(screen);
    props.onUpdate!();
  }

  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const reorderedComponents = Array.from(components);
    const componentToMove = reorderedComponents.splice(source.index, 1)[0];
    reorderedComponents.splice(destination.index, 0, componentToMove);
    setComponents(reorderedComponents);
    updateScreen(title, reorderedComponents);
  };

  return (
    <Paper elevation={ 1 } className="screen-list-item">
      <div className="screen-list-item-header">
        <div className="screen-list-item-title">
          <p>Screen: </p>
          { isEditingTitle
            ? <TextField variant="outlined" label="Text" value={ title } onChange={ (e) => setTitle(e.target.value) } onKeyPress={ (e) => { if (e.key === 'Enter') onConfirmTitle() } } autoFocus />
            : <div>
              <p>{ title }</p>
              <IconButton onClick={ (e) => setIsEditingTitle(true) }><EditIcon /></IconButton>
              <IconButton onClick={ (e) => onDeleteScreen() }><DeleteIcon /></IconButton>
            </div>
          }
        </div>
        <div>
          { props.screen && <IconButton color="primary" onClick={ (e) => setIsOpened(!isOpened) }>{ isOpened ? <RemoveIcon /> : <AddIcon /> }</IconButton> }
        </div>
      </div>
      { (isOpened && screen != null) &&
        <div className="screen-list-item-details">
          <DragDropContext onDragEnd={ handleOnDragEnd }>
            <Droppable droppableId={ screen.uuid }>
              {
                (provided) => (
                  <div ref={ provided.innerRef } { ...provided.droppableProps }>
                    {
                      components.map((component, index) => {
                        return <Draggable draggableId={ component.key } index={ index } key={ component.key }>
                          {
                            (draggableProvided) => (
                              <div { ...draggableProvided.draggableProps } { ...draggableProvided.dragHandleProps } ref={ draggableProvided.innerRef }>
                                <ScreenComponentRenderer
                                  key={ component.key }
                                  component={ component }
                                  onSaveComponent={ onSaveComponent }
                                  onDeleteComponent={ onDeleteComponent }
                                  language={ language }
                                  index={ index }
                                  availableLanguages={ props.availableLanguages }
                                />
                              </div>
                            )
                          }

                        </Draggable>
                      })
                    }
                    { provided.placeholder }
                  </div>
                )
              }

            </Droppable>
          </DragDropContext>
          { getComponentControlsForScreen(screen, onAddComponent) }
        </div>
      }
    </Paper>
  )
}


const getComponentControlsForScreen = (screen, onAddComponent: (type: ScreenComponentType) => void) => {
  return (
    <div className="add-component-controls">
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.TITLE) }>Title</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.RICH_TEXT) }>Rich text</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.IMAGE) }>Image</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.VIDEO) }>Video</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.QUESTION) }>Question</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.MULTIPLE_CHOICE) }>Multiple choice</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.PHOTO_UPLOAD) }>Photo upload</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onAddComponent(ScreenComponentType.OPEN_QUESTION) }>Open question</Button>
    </div>
  )
};

const mapStateToProps = (state) => ({
  languages: state.challenge.currentChallenge.customer.availableLanguages,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenListItem);