import { Button, ButtonGroup } from "@material-ui/core";

export enum JourneyNavigationTab {
  TAB_CONFIGURATION = 'tab_configuration',
  TAB_PROGRESS = 'tab_progress',
}

interface JourneyDetailNavigationProps {
  selectedTab: JourneyNavigationTab;
  onSelectTab: (tab: JourneyNavigationTab) => void;
}

export function JourneyDetailNavigation(props: JourneyDetailNavigationProps) {

  const titleForTab = (tab: JourneyNavigationTab) => {
    switch (tab) {
      case JourneyNavigationTab.TAB_CONFIGURATION:
        return 'Edit configuration';
      case JourneyNavigationTab.TAB_PROGRESS:
        return 'View progress';
      default:
        break;
    }
  }

  return (
    <div className="journey-navigation">
      <ButtonGroup variant="outlined" aria-label="outlined primary button group">
        {
          Object.values(JourneyNavigationTab).map((tab) => {
            const isSelected = tab === props.selectedTab;
            return <Button
              key={ tab }
              onClick={ (e) => props.onSelectTab(tab) }
              color={ isSelected ? 'primary' : undefined }
              variant={ isSelected ? 'contained' : 'outlined' }
            >
              { titleForTab(tab) }
            </Button>
          })
        }
      </ButtonGroup>
    </div>
  );
}

