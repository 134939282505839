import { Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { WarningIcon } from "../../../common/components/icons";
import { extraConfirm } from "../../../util/promptUtil";
import { ChallengeRepository } from "../../challenge/data/ChallengeRepository";
import * as eventUpdateServices from '../domain/eventUpdateServices';
import EventUpdateFilter from "./EventUpdateFilter";

const EventUpdateList = (props) => {
  const challenge = props.challenge;
  const challengeUuid = challenge.uuid;
  const [updates, setUpdates] = useState(null);
  const [filteredUpdates, setFilteredUpdates] = useState([]);

  const refreshUpdates = useCallback(async () => {
    const newUpdates = await eventUpdateServices.getList(challengeUuid);
    setUpdates(newUpdates);
  }, [challengeUuid]);

  useEffect(() => {
    refreshUpdates();
  }, [refreshUpdates]);

  const handleResetClicked = async () => {
    const repository = new ChallengeRepository();
    if (extraConfirm('Are you sure you want to reset the challenge and lose all progress? (yes please)', 'yes please')) {
      await repository.resetChallenge(challenge);
      await refreshUpdates();
      alert('reset complete');
    } else {
      alert('not reset');
    }
  }


  return (
    <div className="event-update-list">
      <h3>Updates:</h3>
      {
        updates === null
          ? <center>Loading...</center>
          : <table cellSpacing={ 0 }>
            <thead>
              <EventUpdateFilter updates={ updates } onFilterUpdates={ setFilteredUpdates } />
            </thead>
            <tbody>
              {
                filteredUpdates.map((update) => {
                  return <ListItem key={ update.id } update={ update } />
                })
              }
            </tbody>
          </table>
      }
      <div className="event-update-controls">
        <Button variant="contained" color="primary" startIcon={ <WarningIcon /> } endIcon={ <WarningIcon /> } onClick={ (e) => handleResetClicked() }>Reset</Button>
      </div>
    </div>
  );
}



export default EventUpdateList;

const ListItem = (props) => {
  const update = props.update;

  return <tr>
    <DateInfo dateString={ update.date } />
    <BasicInfo update={ update } />
    <DataDetails update={ update } />
    <ScoreInfo update={ update } />
  </tr>
}

const DateInfo = (props) => {
  const date = new Date(props.dateString);
  const timeString = date.toTimeString().split(' ')[0]

  return <td className="event-update-date-info">
    <p>
      { date.getDay() + '/' + date.getMonth() + '/' + date.getFullYear() } <br />
      { timeString }
    </p>
  </td>
}

const BasicInfo = (props) => {
  const update = props.update;

  const typeToTextMap = {
    'start': 'Start',
    'end': 'End',
    'screen_first_visit': 'First visit',
    'unlock_question_hint': 'Unlock hint',
    'submit_question_correct': 'Correct answer',
    'submit_question_incorrect': 'Incorrect answer',
    'submit_multiple_choice_correct': 'Correct answer',
    'submit_multiple_choice_incorrect': 'Incorrect answer',
    'photo_upload': 'Photo upload',
  }

  return <>
    <td>
      <p>{ update.event.title }</p>
    </td>
    <td>
      { update.screen ? update.screen.title : '/' }
    </td>
    <td>
      { update.team.name }
    </td>
    <td>
      { update.player.name }
    </td>
    <td>
      {
        typeToTextMap[update.type]
      }
    </td>
  </>


}

const DataDetails = (props) => {
  const type = props.update.type;
  const data = props.update.data;

  let renderedData = <></>;
  if (type === 'submit_question_incorrect' || type === 'submit_question_correct') {
    renderedData = <NormalQuestionInfo data={ data } />;
  } else if (type === 'submit_multiple_choice_correct' || type === 'submit_multiple_choice_incorrect') {
    renderedData = <MultipleChoiceQuestionInfo data={ data } />;
  } else if (type === 'unlock_question_hint') {
    renderedData = <HintInfo data={ data } />;
  } else {
    //TODO: hints (add hint text to event update data)

    renderedData = <DataFallback data={ data } />
  }

  return <td>

    { renderedData }

  </td>
}

const DataFallback = (props) => {
  const data = props.data;
  const datakeys = (data != null ? Object.keys(data) : []);

  //  return <></>;
  return <>{
    datakeys.map((key) => {
      return <span key={ key }>
        <strong>{ key }:</strong> { JSON.stringify(data[key]) } <br />
      </span>
    })
  }
  </>
}

const NormalQuestionInfo = (props) => {
  const data = props.data;
  return <>
    <span>
      <strong>Correct answer: </strong>
      { data['componentAnswer'] }
    </span><br />
    <span>
      <strong>Player answer: </strong>
      { data['playerAnswer'] }
    </span>
  </>
}


const MultipleChoiceQuestionInfo = (props) => {
  const data = props.data;
  const componentAnswers = data['componentAnswer'];
  const playerAnswerKeys = data['playerAnswer'];
  const correctAnswers = componentAnswers.filter((answer) => answer.correct);
  const playerAnswers = componentAnswers.filter((answer) => playerAnswerKeys.includes(answer.key));

  return <>
    <span>
      <strong>Correct answer: </strong>
      <ul>
        { correctAnswers.map((answer) => {
          return <li key={ answer.key }>{ answer.title['nl'] }</li>
        }) }
      </ul>

    </span><br />
    <span>
      <strong>Player answer: </strong>
      <ul>
        { playerAnswers.map((answer) => {
          return <li key={ answer.key }>{ answer.title['nl'] }</li>
        }) }
      </ul>
    </span>
  </>
}

const HintInfo = (props) => {
  const hintTitle = props.data.hintTitle;
  const hintText = props.data.hintText;

  return <td>
    { (hintTitle && hintText)
      && <p>
        <strong>Title:</strong> { hintTitle['nl'] }<br />
        <strong>Text:</strong> { hintText['nl'] }
      </p>
    }
  </td>
}

const ScoreInfo = (props) => {
  const scoreUpdates = props.update.scoreUpdates;

  return <td>
    <p>
      {
        scoreUpdates.map((scoreUpdate, index) => {
          return <span key={ index }>{ scoreUpdate.score }</span>;
        })
      }
    </p>
  </td>
}