
import { IconButton } from "@material-ui/core";
import { useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import ScreenComponentContainer from "./ScreenComponentContainer";
import { ScreenPollComponent } from "../domain/models";

import { GenericScreenComponentConfiguratorProps } from "./ScreenComponentRenderer";
import PollDialog from "../../question/component/PollDialog";

export const ScreenPollComponentConfigurator = (props: GenericScreenComponentConfiguratorProps) => {
  const component = props.component as ScreenPollComponent;
  const language = props.language;
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  const openSettings = () => {
    setIsSettingsDialogOpen(true);
  }

  const onSaveSettings = (component) => {
    props.onSave(component);
  }

  return (
    <ScreenComponentContainer className="screen-poll-component" component={ props.component } onDelete={ props.onDelete } type="Poll">
      <div className="screen-poll-component-content">
        <p>Question: { component.question[language] }</p>
        <IconButton onClick={ (e) => openSettings() }><SettingsIcon /></IconButton>
      </div>
      { isSettingsDialogOpen && <PollDialog component={ component } onClose={ () => setIsSettingsDialogOpen(false) } onSave={ onSaveSettings } languages={ props.languages } /> }
    </ScreenComponentContainer>
  )
}
