import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class MediaRepository {

  async fetchList(searchText, limit, offset, type) {
    var queryParams = '';
    //Some primo querybuilding going on here!
    queryParams += searchText != null ? 'search_tag=' + searchText + '&' : '';
    queryParams += limit != null ? 'limit=' + limit + '&' : '';
    queryParams += offset != null ? 'offset=' + offset + '&' : '';
    queryParams += type != null ? 'type=' + type + '&' : '';

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/media/list?' + queryParams,
      {}
    );

    const responseBody = await response.json();        
    return responseBody;
  }

  async createMedia(type, tags, data) {
    const postBody = {
      type: type,
      data: data,
      tags: tags,
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/media/create', 
      {                
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async editMedia(mediaKey, data, tags) {
    const postBody = {
      key: mediaKey,
      data: data,
      tags: tags,
    };

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/media/edit', 
      {                
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

}