import { Button, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AddIcon } from "../../../common/components/icons";
import { createMedia, editMedia, fetchList } from "../redux/mediaOperations";
import ImageEditForm from "./ImageEditForm";
import MediaList from "./MediaList";
import VideoEditForm from "./VideoEditForm";

export const MediaType = {
  IMAGE: 'image',
  VIDEO: 'video',
};

const defaultImageItem = { type: MediaType.IMAGE };
const defaultVideoItem = { type: MediaType.VIDEO };
const defaultItems = [defaultImageItem, defaultVideoItem];

const MediaDialog = (props) => {
  const fetchList = props.fetchList;
  const defaultItem = defaultItems.find((item) => item.type === props.selectionType);
  const [mediaRequestState, setMediaRequestState] = useState({ searchText: null, limit: 12, offset: null, type: props.selectionType });
  const [mediaItems, setMediaItems] = useState(null);
  const [selectedMediaItem, setSelectedMediaItem] = useState(defaultItem);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchMediaItems = useCallback(() => {
    fetchList(mediaRequestState).then(unwrapResult).then((items) => {
      setMediaItems(items);
    }).catch((e) => {
      setErrorMessage('Error' + e.toString())
    });
  }, [fetchList, mediaRequestState]);

  useEffect(() => {
    fetchMediaItems();
  }, [fetchMediaItems]);

  const handleMediaItemSelected = useCallback((mediaItem) => {
    setSelectedMediaItem(mediaItem);
  }, []);

  const handleMediaRequestStateUpdated = useCallback((mediaRequestState) => {
    setMediaRequestState(mediaRequestState);
  }, []);

  const handleMediaFormSave = async (data, tags) => {
    if (selectedMediaItem.key != null) {
      await props.editMedia({ key: selectedMediaItem.key, data: data, tags: tags }).then(unwrapResult);
      fetchMediaItems();
    } else {
      await props.createMedia({ ...selectedMediaItem, data: data, tags: tags }).then(unwrapResult);
      setMediaRequestState({
        ...mediaRequestState,
        offset: 0,
      });
    }

    setSelectedMediaItem(defaultItem);
  }

  let formToRender = <div></div>;
  if (selectedMediaItem != null) {
    if (props.selectionType === MediaType.IMAGE) {
      formToRender = <ImageEditForm mediaItem={ selectedMediaItem } onSave={ handleMediaFormSave } />;
    } else if (selectedMediaItem.type === MediaType.VIDEO) {
      formToRender = <VideoEditForm mediaItem={ selectedMediaItem } onSave={ handleMediaFormSave } />;
    }
  }

  return (
    <Dialog open={ true } TransitionComponent={ Transition } maxWidth={ 'md' } fullWidth={ true }>
      <DialogContent>
        <div className="media-dialog dialog-content">
          <h2>{ 'Media' }</h2>
          <div className="media-manager">
            <MediaList
              items={ mediaItems }
              itemsPerPage={ mediaRequestState.limit }
              selectedItem={ selectedMediaItem }
              onRequestStateUpdated={ handleMediaRequestStateUpdated }
              onItemSelected={ handleMediaItemSelected }
              typeFilter={ props.selectionType }
            />
            {
              formToRender
            }
          </div>
          <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => { setSelectedMediaItem({ ...defaultItem }) } }>Add { props.selectionType }</Button>
          { errorMessage != null && <p className="error dialog-error">{ errorMessage }</p> }
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => props.onClose() }>
          Cancel
        </Button>
        <Button onClick={ (e) => {
          props.onConfirm(selectedMediaItem);
          props.onClose();
        } } disabled={ !(selectedMediaItem !== null && selectedMediaItem.key != null) }>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ ref } { ...props } />;
});

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({
  fetchList: (request) => dispatch(fetchList(request)),
  createMedia: (data) => dispatch(createMedia(data)),
  editMedia: (data) => dispatch(editMedia(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDialog);


