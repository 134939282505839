import { Button, IconButton, TextField } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { connect } from "react-redux";
import { EditIcon } from "../../../common/components/icons";
import { createTeam, editTeam } from "../redux/teamOperations";
import MemberList from "./MemberList";


const TeamGridItem = (props) => {
  const team = props.team;
  const [isEditingName, setIsEditingName] = useState(team ? false : true);
  const [teamName, setTeamName] = useState(team ? team.name : '');

  const onConfirmTeamName = () => {
    if (team != null) {
      props.editTeam({ uuid: team.uuid, name: teamName }).then(unwrapResult).then((result) => {

      }).catch(handleError);

      setIsEditingName(false);
    } else {
      props.createTeam({ name: teamName }).then(unwrapResult).then((result) => {
        props.onCreated();
      }).catch(handleError);
    }

  }

  const handleError = (e) => {
    alert('Something went wrong: ' + e.toString());
  }

  return (
    <div className="team-grid-item">
      <div className="team-grid-item-header">
        { isEditingName
          ? <TextField
            className="form-textfield"
            variant="standard"
            onChange={ (e) => setTeamName(e.target.value) }
            value={ teamName }
            onKeyPress={ (e) => { if (e.key === 'Enter') onConfirmTeamName() } }
            autoFocus
          />
          : <div className="team-grid-item-header-contents">
            <h3>{ team.name }</h3>
            <IconButton className="edit-button" onClick={ (e) => setIsEditingName(true) }>
              <EditIcon />
            </IconButton>
          </div>
        }
      </div>
      { team != null && <MemberList team={ team } /> }
      { props.canSelect
        ? <Button className="assign-players-button" variant="contained" color="primary" onClick={ (e) => props.onAssignPressed() }>Assign players here</Button>
        : <div />
      }
    </div>
  );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  editTeam: (team) => dispatch(editTeam(team)),
  createTeam: (team) => dispatch(createTeam(team)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamGridItem);


