
import { Button, IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponentContainer from "./ScreenComponentContainer";

export const ScreenRichTextComponentConfigurator = (props) => {
  const component = props.component;
  const language = props.language;
  const [richText, setRichText] = useState('');
  const [isEditing, setIsEditing] = useState(component.richText[language] ? false : true);

  useEffect(() => {
    setRichText(component.richText ? (component.richText[language] ? component.richText[language] : '') : '');
  }, [language, component.richText]);

  const onConfirm = () => {
    var updatedRichText = {
      ...(component.richText ? component.richText : {})
    };
    updatedRichText[language] = richText;

    props.onSave({
      ...component,
      richText: updatedRichText,
    });
    setIsEditing(false);
  }

  return (
    <ScreenComponentContainer className="screen-rich-text-component" component={ props.component } onDelete={ props.onDelete } type="Rich text">
      <div className="screen-rich-text-component-content">
        <p>Content:</p>
        { isEditing
          ? <div className="rich-text-editor">
            <ReactQuill
              theme="snow"
              value={ richText }
              onChange={ setRichText }
              modules={ { clipboard: { matchVisual: false } } }
            />
          </div>
          : <div className="rich-text" dangerouslySetInnerHTML={ { __html: richText } }></div>
        }
        { isEditing
          ? <Button variant="contained" onClick={ onConfirm } color="primary">Save</Button>
          : <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
        }
      </div>
    </ScreenComponentContainer>
  );
}
