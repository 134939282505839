import { Button, Checkbox, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import Transition from "../../../common/components/Transition";

const PhotoUploadSettingsDialog = (props) => {
  const component = props.component;
  const [requiredForValidation, setRequiredForValidation] = useState(component.requiredForValidation ? component.requiredForValidation : false);
  const [moderationRequired, setModerationRequired] = useState(component.moderationRequired ? component.moderationRequired : false);
  const [points, setPoints] = useState(component.points ? component.points : 0);

  const onConfirm = () => {
    //Ensure points is always either 0 or a value
    const parsedPoints = isNaN(parseInt(points)) ? 0 : parseInt(points);
    const updatedComponent = {
      ...component,
      requiredForValidation: requiredForValidation,
      moderationRequired: moderationRequired,
      points: parsedPoints,
    }

    props.onSave(updatedComponent);
    props.onClose();
  }

  return (
    <Dialog open={ true } TransitionComponent={ Transition } maxWidth="lg" onClose={ (e) => { props.onClose() } }>
      <DialogContent>
        <div className="photo-upload-dialog dialog-content">
          <div className="settings-header">
            <h2>Photo upload settings</h2>
          </div>
          <div className="settings-content">
            <div>
              <span>Upload required to continue in screen:</span>
              <Checkbox
                checked={ requiredForValidation }
                onChange={ (e) => { setRequiredForValidation(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div>
              <span>Moderation required:</span>
              <Checkbox
                checked={ moderationRequired }
                onChange={ (e) => { setModerationRequired(e.target.checked) } }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div>
              <TextField variant="filled" label="Points" value={ points } type="number" onChange={ (e) => setPoints(e.target.value) } size="small" />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => props.onClose() }>
          Cancel
        </Button>
        <Button onClick={ (e) => onConfirm() }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoUploadSettingsDialog);


