import { PhotoUploadRepository } from "../data/PhotoUploadRepository";

const repository = new PhotoUploadRepository();

const getList = async (challengeUuid) => {
  const uploads = await repository.getList(challengeUuid);

  return uploads.map((upload) => {
    return {
      eventUpdateId: upload.id,
      eventTitle: upload.event.title,
      screenTitle: upload.screen.title,
      componentTitle: upload.data.componentTitle['nl'], //Hardcode NL for now - #YOLO
      teamName: upload.team.name,
      playerName: upload.player.name,
      date: new Date(upload.date),
      privateImageUrl: upload.privateImageUrl,
      moderationRequired: upload.data.moderationRequired,
      moderationStatus: upload.data.moderationStatus,
      rejectionReason: upload.data.rejectionReason
    }
  });
}

const moderateUpload = async (upload, approved, rejectionReason) => {
  const result = await repository.moderateUpload(upload.eventUpdateId, approved, rejectionReason);

  return result;
}

const getPrivateImageBlobUrl = async (imageUrl: string, shareToken: string | undefined) => {
  const result = await repository.getPrivateImageBlobUrl(imageUrl, shareToken);

  return result;
}

const photoUploadServices = {
  getList,
  moderateUpload,
  getPrivateImageBlobUrl,
}

export default photoUploadServices;