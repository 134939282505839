import { useCallback, useEffect, useState } from "react";
import { getScores } from "../domain/teamServices";

const TeamScores = (props) => {
  const challengeUuid = props.challengeUuid;
  const [scores, setScores] = useState(null);

  const refreshScores = useCallback(async () => {
    let newScores = await getScores(challengeUuid);
    setScores(newScores.sort((a, b) => b.score - a.score));
  }, [challengeUuid, setScores]);

  useEffect(() => {
    refreshScores();
  }, [refreshScores]);

  return (
    <div className="team-scores">
      <h3>Scores:</h3>
      <ul>
        { scores === null
          ? <></>
          : scores.map(score => {
            return <li key={ score.team.uuid }><strong>{ score.team.name + ': ' } </strong>{ score.score }</li>
          })
        }
      </ul>
    </div>
  )
}

export default TeamScores;