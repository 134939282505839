import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import { EditIcon } from "../../../common/components/icons";
import { LanguageContext } from "../../../common/components/LanguagePicker";
import { LocalizedValue } from "../../../common/domain/models";
import journeyServices from "../domain/journeyServices";
import { JourneyDetail } from "../domain/models";


interface JourneyDetailsEditorProps {
  journey: JourneyDetail,
  onSaveChanges: () => void,
}

export function JourneyDetailsEditor({ journey, onSaveChanges }: JourneyDetailsEditorProps) {
  const language = useContext(LanguageContext);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState<LocalizedValue>(journey.title);
  const [superTitle, setSuperTitle] = useState<LocalizedValue>(journey.superTitle);

  const confirm = async () => {
    await journeyServices.editJourney(journey.uuid, title, superTitle);
    onSaveChanges();
    setIsEditing(false);
  }

  const titleTextFieldValue = title[language] ? title[language] : '';
  const superTitleTextFieldValue = superTitle[language] ? superTitle[language] : '';

  return <Paper elevation={ 1 } className='journey-details-editor'>
    <h2>Info</h2>
    {
      isEditing
        ? <div className='journey-details-editor-editing'>
          <TextField className="form-textfield" onChange={ (e) => setTitle({ ...title, [language]: e.target.value }) } label="Title" variant="filled" value={ titleTextFieldValue } />
          <TextField className="form-textfield" onChange={ (e) => setSuperTitle({ ...superTitle, [language]: e.target.value }) } label="Supertitle" variant="filled" value={ superTitleTextFieldValue } />
          <Button variant="contained" color="primary" onClick={ (e) => confirm() }>Save</Button>
        </div>
        : <div className='journey-details-editor-info'>
          <div>
            <strong>Title: </strong>
            <span>{ journey.title[language] }</span>
          </div>
          <div>
            <strong>Supertitle: </strong>
            <span>{ journey.superTitle[language] }</span>
          </div>
          <IconButton color="primary" onClick={ () => { setIsEditing(true) } }><EditIcon /></IconButton>
        </div>
    }
  </Paper>;
}