import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SaveIcon } from "../../../common/components/icons";
import LanguagePicker, { Language } from "../../../common/components/LanguagePicker";

const VideoEditForm = (props) => {
  const languages = [Language.DUTCH, Language.ENGLISH, Language.FRENCH];
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const [thumbnailUrl, setThumbnailUrl] = useState({});
  const [streamUrl, setStreamUrl] = useState({});
  const [tags, setTags] = useState(null);

  useEffect(() => {

    if (props.mediaItem.key != null) {
      setThumbnailUrl(props.mediaItem.thumbnailUrl);
      setStreamUrl(props.mediaItem.streamUrl);
      setTags(props.mediaItem.tags ? props.mediaItem.tags : '');
    } else {
      setTags('');
      setThumbnailUrl({}); //Url's are language dependent, so we store them in a map {language: url}
      setStreamUrl({});
    }
  }, [props.mediaItem]);

  const onSavePressed = () => {
    const data = {
      thumbnailUrl: thumbnailUrl,
      streamUrl: streamUrl,
    }
    props.onSave(data, tags);
  }

  const updateStreamUrl = (url) => {
    const streamUrlToUpdate = { ...streamUrl };
    streamUrlToUpdate[currentLanguage] = url;
    setStreamUrl(streamUrlToUpdate);
  }

  const updateThumbnailUrl = (url) => {
    const thumbnailUrlToUpdate = { ...thumbnailUrl };
    thumbnailUrlToUpdate[currentLanguage] = url;
    setThumbnailUrl(thumbnailUrlToUpdate);
  }

  const canSave = thumbnailUrl[currentLanguage] && streamUrl[currentLanguage];


  return (
    <div className="media-edit-form">
      <LanguagePicker currentLanguage={ currentLanguage } languages={ languages } onChange={ (language) => setCurrentLanguage(language) } />
      <img className="media-form-image" alt="" src={ thumbnailUrl[currentLanguage] || '' } width="100" height="100" />
      <TextField variant="filled" label="Thumbnail URL" value={ thumbnailUrl[currentLanguage] || '' } onChange={ (e) => updateThumbnailUrl(e.target.value) } size="small" fullWidth={ true } InputLabelProps={ { shrink: true } } />
      <TextField variant="filled" label="Stream URL" value={ streamUrl[currentLanguage] || '' } onChange={ (e) => updateStreamUrl(e.target.value) } size="small" fullWidth={ true } InputLabelProps={ { shrink: true } } />
      <TextField variant="filled" label="Search tags" value={ tags || '' } onChange={ (e) => setTags(e.target.value) } size="small" fullWidth={ true } InputLabelProps={ { shrink: true } } />
      <Button variant="contained" color="primary" startIcon={ <SaveIcon /> } onClick={ (e) => { onSavePressed() } } disabled={ !canSave }>Save</Button>
    </div>
  )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(VideoEditForm);