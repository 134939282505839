import { Checkbox } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const UnassignedPlayerList = (props) => {
  const { players, onSelectionUpdated } = props;
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  useEffect(() => {
    setSelectedPlayers([]);
  }, [players]);

  useEffect(() => {
    onSelectionUpdated(selectedPlayers);
  }, [onSelectionUpdated, selectedPlayers]);

  const onTogglePlayer = (player, checked) => {
    if (checked) {
      setSelectedPlayers([...selectedPlayers, player]);
    } else {
      setSelectedPlayers(selectedPlayers.filter((selectedPlayer) => selectedPlayer.uuid !== player.uuid));
    }
  }

  return (
    <div className="unassigned-player-list">
      <h3>Unassigned players</h3>
      { players.map(player =>
        <div className="unassigned-player-item" key={ player.uuid }>
          <Checkbox
            checked={ selectedPlayers.includes(player) }
            onChange={ (e) => onTogglePlayer(player, e.target.checked) }
            color="primary"
            inputProps={ { 'aria-label': 'primary checkbox' } }
          />
          <span>{ player.name }</span>
        </div>
      ) }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedPlayerList);