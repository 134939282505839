import { Button, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { activateChallenge, createChallenge, deleteChallenge, editChallenge, fetchList } from "../redux/challengeOperations";
import AddIcon from "@material-ui/icons/Add";
import ChallengeListItem from "./ChallengeListItem";
import { extraConfirm } from "../../../util/promptUtil";

const ChallengeList = (props) => {
  const customer = props.customer;
  const fetchList = props.fetchList;
  const [isCreatingChallenge, setIsCreatingChallenge] = useState(false);

  useEffect(() => {
    fetchList(customer.uuid);
  }, [fetchList, customer]);

  const onCreateChallengePressed = () => {
    setIsCreatingChallenge(true);
  };

  const onSaveChallenge = (challenge) => {
    if (challenge.uuid) {
      props.editChallenge(customer.uuid, challenge);
    } else {
      props.createChallenge(customer.uuid, challenge.title);
      setIsCreatingChallenge(false);
    }
  }

  const onDeleteChallenge = (challenge) => {
    if (extraConfirm('Are you sure? This will delete the challenge and EVERYTHING RELATED: teams, events, player progress... (type yes to confirm)')) {
      props.deleteChallenge(customer.uuid, challenge);
    }

  }

  const onActivateChallenge = (challengeUuid) => {
    props.activateChallenge(customer.uuid, challengeUuid);
  }

  return (
    <div>
      <Paper elevation={ 0 } className="challenge-list">
        <h3>Challenges:</h3>
        { props.challenges != null && props.challenges.map(challenge => {
          return <ChallengeListItem
            challenge={ challenge }
            key={ challenge.uuid }
            onSave={ onSaveChallenge }
            onActivate={ () => onActivateChallenge(challenge.uuid) }
            onDelete={ () => onDeleteChallenge(challenge) }
          />;
        }) }
        {
          isCreatingChallenge && <ChallengeListItem isNew={ true } challenge={ { title: "New challenge" } } key={ 'new' } onSave={ onSaveChallenge } />
        }
        <div className="challenge-list-controls">
          <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onCreateChallengePressed() }>Create challenge</Button>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  challenges: state.challenge.listPerCustomer[ownProps.customer.uuid],
});

const mapDispatchToProps = (dispatch) => ({
  fetchList: (customerUuid) => dispatch(fetchList(customerUuid)),
  editChallenge: (customerUuid, challenge) => dispatch(editChallenge({ challenge: challenge, customerUuid: customerUuid })),
  createChallenge: (customerUuid, title) => dispatch(createChallenge({ title: title, customerUuid: customerUuid })),
  activateChallenge: (customerUuid, challengeUuid) => dispatch(activateChallenge({ challengeUuid: challengeUuid, customerUuid: customerUuid })),
  deleteChallenge: (customerUuid, challenge) => dispatch(deleteChallenge({ challenge: challenge, customerUuid: customerUuid })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeList);