import { TextField } from "@material-ui/core";
import { HexColorPicker } from "react-colorful";


interface ColorPickerProps {
  title: string,
  color: string;
  onChange: (color: string) => void;
}

export function ColorPicker({ title, color, onChange }: ColorPickerProps) {
  return <div className='color-picker'>
    <TextField className='form-textfield' onChange={ (e) => onChange(e.target.value) } label={ title } variant="filled" value={ color } />
    <HexColorPicker color={ color } onChange={ onChange } />
  </div>;
}