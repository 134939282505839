import { Button, IconButton } from "@material-ui/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { DeleteIcon, ImageIcon, SettingsIcon, VideoIcon } from "../../../common/components/icons";
import { extraConfirm } from "../../../util/promptUtil";
import { JourneyDetailContext } from "../../journey/component/JourneyDetailScreen";
import { Snack } from "../../snack/domain/models";
import { SnackScreen, SnackScreenType } from "../domain/models";
import snackScreenServices from "../domain/snackScreenServices";
import { SnackImageScreenDialog } from "./SnackImageScreenDialog";
import { SnackScreenCreator } from "./SnackScreenCreator";
import { SnackVideoScreenDialog } from "./SnackVideoScreenDialog";

interface SnackScreenListProps {
  snack: Snack;
}

export function SnackScreenList({ snack }: SnackScreenListProps) {
  const journeyDetail = useContext(JourneyDetailContext);
  const [snackScreens, setSnackScreens] = useState<SnackScreen[]>([]);
  const [isCreatingScreen, setIsCreatingScreen] = useState(false);
  const [editingScreen, setEditingScreen] = useState<SnackScreen | null>(null);

  const refreshSnackScreens = useCallback(async () => {
    const screens = await snackScreenServices.getList(snack.uuid);
    setSnackScreens(screens);
  }, [snack]);

  useEffect(() => {
    refreshSnackScreens();
  }, [refreshSnackScreens]);

  const handleScreenCreated = async () => {
    setIsCreatingScreen(false);
    await refreshSnackScreens();
  };

  const handleEditScreenClose = (shouldRefresh: boolean) => {
    setEditingScreen(null);
    if (shouldRefresh) {
      refreshSnackScreens();
    }
  }

  const handleDeleteScreenClicked = async (screen: SnackScreen) => {
    if (extraConfirm('Are you sure you want to delete this screen? type "yes" to confirm')) {
      await snackScreenServices.deleteSnackScreen(screen.uuid);
      await refreshSnackScreens();
    }
  };

  return <div className='snack-screen-list'>
    <h4>Screens:</h4>
    <div className='snack-screen-list-items'>
      {
        snackScreens.map((screen) => {
          return <div key={ screen.uuid } className='snack-screen-item'>
            <div className='snack-screen-item-info'>
              { screen.type === SnackScreenType.IMAGE && <ImageIcon /> }
              { screen.type === SnackScreenType.VIDEO && <VideoIcon /> }
              <span>{ screen.title }</span>

            </div>
            <div className='snack-screen-item-controls'>
              <IconButton onClick={ (e) => setEditingScreen(screen) }><SettingsIcon /></IconButton>
              <IconButton onClick={ (e) => handleDeleteScreenClicked(screen) }><DeleteIcon /></IconButton>
            </div>
          </div>
        })
      }
    </div>
    {
      isCreatingScreen
        ? <SnackScreenCreator snack={ snack } onCreated={ handleScreenCreated } />
        : <Button variant="contained" color="primary" onClick={ (e) => setIsCreatingScreen(true) }>Add new screen</Button>
    }
    {
      editingScreen?.type === SnackScreenType.IMAGE && <SnackImageScreenDialog customer={ journeyDetail.customer } screen={ editingScreen } onRequestClose={ handleEditScreenClose } />
    }
    {
      editingScreen?.type === SnackScreenType.VIDEO && <SnackVideoScreenDialog customer={ journeyDetail.customer } screen={ editingScreen } onRequestClose={ handleEditScreenClose } />
    }

  </div>;
}