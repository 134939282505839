import { Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useRef, useState } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import ReactQuill from "react-quill";
import { ColorPicker } from "../../../common/components/ColorPicker";
import LanguagePicker from "../../../common/components/LanguagePicker";
import { LocalizedValue } from "../../../common/domain/models";
import formatHelpers from "../../../helpers/formatHelpers";
import { DefaultDialogTransition } from "../../../helpers/ui";
import { Customer } from "../../customer/data/models";
import MediaDialog, { MediaType } from "../../media/component/MediaDialog";
import { SnackScreenContentPosition, SnackVideoScreen } from "../domain/models";
import snackScreenServices from "../domain/snackScreenServices";
import { SnackScreenComponentManager } from "./SnackScreenComponentManager";

interface SnackVideoScreenDialogProps {
  screen: SnackVideoScreen;
  customer: Customer;
  onRequestClose: (shouldRefresh: boolean) => void;
}

export function SnackVideoScreenDialog({ screen, customer, onRequestClose }: SnackVideoScreenDialogProps) {
  const [title, setTitle] = useState(screen.title);
  const [media, setMedia] = useState(screen.media);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [text, setText] = useState(screen.text ? screen.text : { nl: '' } as LocalizedValue);
  const [textFontSize, setTextFontSize] = useState(screen.layoutParameters.textFontSize ? screen.layoutParameters.textFontSize : 15);
  const [contentPosition, setContentPosition] = useState(screen.layoutParameters.contentPosition ? screen.layoutParameters.contentPosition : SnackScreenContentPosition.BOTTOM);
  const [backgroundColor, setBackgroundColor] = useState(screen.layoutParameters.backgroundColor ? screen.layoutParameters.backgroundColor : '#ffffff');
  const [textColor, setTextColor] = useState(screen.layoutParameters.textColor ? screen.layoutParameters.textColor : '#000000');
  const [language, setLanguage] = useState(customer.availableLanguages[0]);
  const [component, setComponent] = useState(screen.component);

  const videoPlayerRef = useRef<HTMLVideoElement>(null);

  const onSelectMediaClicked = () => {
    setMediaDialogOpen(true);
  };

  const onSaveClicked = async () => {
    await snackScreenServices.editSnackVideoScreen({
      uuid: screen.uuid,
      title: title,
      mediaKey: media?.key,
      text: text,
      layoutParameters: {
        textFontSize: textFontSize,
        backgroundColor: backgroundColor,
        textColor: textColor,
        contentPosition: contentPosition,
      },
      component: component,
      weight: screen.weight,
    });
    onRequestClose(true);
  }

  const textValue: string = (text[language] ? text[language] : '') as string;

  return <Dialog open={ true } TransitionComponent={ DefaultDialogTransition } maxWidth={ false } onClose={ () => onRequestClose(false) }>
    <DialogContent>
      <div className='snack-screen-dialog dialog-content'>
        <div className='snack-screen-settings'>
          <h2>{ title }</h2>
          <div className='snack-screen-basic'>
            <TextField className='form-textfield' value={ title } onChange={ (e) => setTitle(e.target.value) } label='Title' variant='filled' />
          </div>
          <Button variant='contained' color='primary' onClick={ onSelectMediaClicked }>Select media</Button>
          <div className='snack-screen-text'>
            <ReactQuill
              theme='snow'
              value={ textValue }
              onChange={ (value) => setText({ ...text, [language]: value }) }
              modules={ { clipboard: { matchVisual: false } } }
              style={ { height: '200px' } }
            />
            <div className='snack-screen-text-settings'>
              <LanguagePicker languages={ customer.availableLanguages } currentLanguage={ language } onChange={ setLanguage } />
              <ContentPositionPicker position={ contentPosition } onChange={ setContentPosition } />
              <TextField className='form-textfield' value={ textFontSize } onChange={ (e) => setTextFontSize(parseInt(e.target.value)) } label='Font size' variant='filled' type='number' />
            </div>
          </div>
          <div className='snack-screen-color'>
            <ColorPicker title='Background color' color={ backgroundColor } onChange={ setBackgroundColor } />
            <ColorPicker title='Text color' color={ textColor } onChange={ setTextColor } />
          </div>
          <SnackScreenComponentManager
            availableLanguages={ customer.availableLanguages }
            currentLanguage={ language }
            currentComponent={ component }
            onUpdate={ setComponent }
          />
        </div>
        <div className='snack-screen-preview-wrapper'>
          <div className='snack-screen-preview'>
            <div className='snack-screen-preview-background'>
              <div className='snack-screen-preview-background-color' style={ { backgroundColor: backgroundColor } }></div>
              {
                media && <ReactHlsPlayer
                  className='snack-screen-preview-background-video'
                  playerRef={ videoPlayerRef }
                  src={ media.streamUrl[language] }
                  loop={ true }
                  autoPlay={ true }
                />
              }
              {/* <div className='snack-screen-preview-background-image' style={ { backgroundImage: `url(${media?.url})` } }></div> */ }
              <div className='snack-screen-preview-content' style={ { justifyContent: mapContentPositionToJustifyContent(contentPosition) } }>
                <div className='snack-screen-preview-content-text'
                  dangerouslySetInnerHTML={ { __html: textValue } }
                  style={ {
                    color: textColor,
                    backgroundColor: backgroundColor,
                    fontSize: textFontSize + 'px',
                  } }>

                </div>
              </div>
            </div>

          </div>
        </div>
        { mediaDialogOpen && <MediaDialog onClose={ () => setMediaDialogOpen(false) } onConfirm={ setMedia } selectionType={ MediaType.VIDEO } /> }
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={ () => onRequestClose(false) }>
        Cancel
      </Button>
      <Button onClick={ onSaveClicked }>
        Save
      </Button>
    </DialogActions>
  </Dialog>;
}

function mapContentPositionToJustifyContent(position: SnackScreenContentPosition): string {
  switch (position) {
    case SnackScreenContentPosition.BOTTOM:
      return 'flex-end';
    case SnackScreenContentPosition.TOP:
      return 'flex-start';
    case SnackScreenContentPosition.CENTER:
      return 'center';
  }
}

interface ContentPositionPickerProps {
  position: SnackScreenContentPosition;
  onChange: (position: SnackScreenContentPosition) => void;
}

function ContentPositionPicker({ position, onChange }: ContentPositionPickerProps) {
  return (
    <FormControl className="language-picker" variant="filled">
      <InputLabel>Text position</InputLabel>
      <Select value={ position } onChange={ (e) => onChange(e.target.value as SnackScreenContentPosition) }>
        { Object.keys(SnackScreenContentPosition).map((position) => {
          return (
            <MenuItem key={ position } value={ position }>{ formatHelpers.capitalize(position) }</MenuItem>
          )
        }) }
      </Select>
    </FormControl>
  );
}