import { z } from "zod";
import { LocalizedValueSchema } from "../../../common/domain/models";
import { CustomerSchema } from "../../customer/data/models";

export type Journey = z.infer<typeof JourneySchema>;
export const JourneySchema = z.object({
  uuid: z.string(),
  active: z.boolean(),
  title: LocalizedValueSchema,
  superTitle: LocalizedValueSchema,
});

export type JourneyDetail = z.infer<typeof JourneyDetailSchema>;
export const JourneyDetailSchema = JourneySchema.extend({
  customer: CustomerSchema,
});