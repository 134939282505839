import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { useState } from "react";
import { connect } from "react-redux";
import { logout } from "../../features/user/redux/userOperations";

const HeaderMenu = (props) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const onOpenMenu = (origin) => {
        setMenuAnchor(origin);
    };

    return (
        <div>
            <Button variant="contained" color="primary" startIcon={ <SettingsIcon /> } onClick={ (e) => onOpenMenu(e.currentTarget) }>Options</Button>
            <PopoutMenu
                anchorEl={ menuAnchor }
                open={ Boolean(menuAnchor) }
                onLogout={ () => props.logout() }
                onClose={ () => setMenuAnchor(null) }
            />
        </div>
    )
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);

const PopoutMenu = (props) => {
    return (
        <Menu
            getContentAnchorEl={ null }
            anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'right',
            } }
            transformOrigin={ {
                vertical: 'top',
                horizontal: 'right',
            } }
            { ...props }
        >
            <MenuItem onClick={ (e) => props.onLogout() }>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={ 'Log out'/*TODO: translate */ } />
            </MenuItem>
        </Menu>
    )
}