import { SnackScreen } from "../domain/models";
import * as apiHelper from "../../../helpers/apiHelpers";
import ApplicationConfig from "../../../ApplicationConfig";
import { ApiError } from "../../../common/domain/models";
import { CreateSnackScreenData, EditSnackScreenData } from "./models";

export class SnackScreenRepository {

  async getListBySnackUuid(snackUuid: string): Promise<SnackScreen[]> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack-screen/list?snack_uuid=' + snackUuid,
      {}
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();

      return responseBody;
    }

    throw new ApiError('Error fetching snack screen list', response);
  }

  async create(data: CreateSnackScreenData): Promise<void> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack-screen/create',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (response && response.status === 200) {
      return;
    }

    throw new ApiError('Error creating snack screen', response);
  }

  async edit(data: EditSnackScreenData): Promise<void> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack-screen/edit',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (response && response.status === 200) {
      return;
    }

    throw new ApiError('Error editing snack screen', response);
  }

  async delete(uuid: string): Promise<void> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/snack-screen/delete',
      {
        method: 'POST',
        body: JSON.stringify({ uuid }),
      }
    );

    if (response && response.status === 200) {
      return;
    }

    throw new ApiError('Error deleting snack screen', response);
  }
}