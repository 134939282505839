import { Button } from "@material-ui/core";
import { AddIcon } from "../../../common/components/icons";
import { Language } from "../../../common/components/LanguagePicker";
import { ScreenComponentRenderer } from "../../screen_component/component/ScreenComponentRenderer";
import { ScreenComponent, ScreenComponentType } from "../../screen_component/domain/models";
import screenComponentServices from "../../screen_component/domain/screenComponentServices";
import { SnackScreenComponent } from "../domain/models";

interface SnackScreenComponentManagerProps {
  currentLanguage: Language,
  availableLanguages: Language[],
  currentComponent?: SnackScreenComponent,
  onUpdate: (component?: SnackScreenComponent) => void,
}

export function SnackScreenComponentManager({ currentLanguage, availableLanguages, currentComponent, onUpdate }: SnackScreenComponentManagerProps) {

  const onSetComponent = (type: ScreenComponentType) => {
    const newComponent = screenComponentServices.createDefaultComponentForType(type) as SnackScreenComponent;
    onUpdate(newComponent);
  }

  const handleComponentOnSave = (component: ScreenComponent) => {
    onUpdate(component as SnackScreenComponent);
  }

  const handleComponentOnDelete = (component: ScreenComponent) => {
    onUpdate(undefined);
  }

  if (currentComponent) {
    return <ScreenComponentRenderer
      index={ 0 }
      component={ currentComponent }
      onSaveComponent={ handleComponentOnSave }
      onDeleteComponent={ handleComponentOnDelete }
      language={ currentLanguage }
      availableLanguages={ availableLanguages }
    />;
  } else {
    return <div className='screen-component-manager-controls'>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onSetComponent(ScreenComponentType.MULTIPLE_CHOICE) }>Multiple choice</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onSetComponent(ScreenComponentType.PHOTO_UPLOAD) }>Photo upload</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onSetComponent(ScreenComponentType.OPEN_QUESTION) }>Open question</Button>
      <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => onSetComponent(ScreenComponentType.POLL) }>Poll</Button>
    </div>;
  }
}