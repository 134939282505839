import { z } from "zod";

export type LocalizedValue = z.infer<typeof LocalizedValueSchema>;
export const LocalizedValueSchema = z.object({
  nl: z.string(),
  fr: z.optional(z.string()),
  en: z.optional(z.string()),
})

export class ApiError extends Error {

  extra: any;

  constructor(message: any, extra?: any) {
    super(message);
    this.name = 'ApiError';
    this.extra = extra;
  }
}