
import { IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponentContainer from "./ScreenComponentContainer";

export const ScreenTitleComponentConfigurator = (props) => {
  const component = props.component;
  const language = props.language;
  const [titleText, setTitleText] = useState('');
  const [isEditing, setIsEditing] = useState(component.text[language] === '');

  useEffect(() => {
    setTitleText(component.text ? (component.text[language] ? component.text[language] : '') : '');
  }, [language, component.text]);

  const onConfirm = () => {
    var updatedText = {
      ...(component.text ? component.text : {})
    };
    updatedText[language] = titleText;

    props.onSave({
      ...component,
      text: updatedText,
    });
    setIsEditing(false);
  }

  //TODO: title type
  return (
    <ScreenComponentContainer className="screen-title-component" component={ props.component } onDelete={ props.onDelete } type="Title">
      { isEditing
        ? <TextField variant="outlined" label="Title" value={ titleText } onChange={ (e) => setTitleText(e.target.value) } onKeyPress={ (e) => { if (e.key === 'Enter') onConfirm() } } autoFocus />
        : <div className="screen-title-component-content">
          <p>Content: { titleText }</p>
          <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
        </div>
      }
    </ScreenComponentContainer>
  )

}
