
import { Button, IconButton, TextField } from "@material-ui/core";
import { DeleteIcon } from "../../../common/components/icons";
import { Language } from "../../../common/components/LanguagePicker";
import { generateShortKey } from "../../../util/uniqueIdUtil";
import { QuestionHint } from "../domain/models";

interface HintListProps {
  onUpdate: (hints: QuestionHint[]) => void,
  hints: QuestionHint[],
  languages: Language[],
  language: string,
}

const HintList = (props: HintListProps) => {

  const onAddHint = () => {
    props.onUpdate([
      ...props.hints,
      {
        key: generateShortKey(),
        minutesUntilUnlocked: 10,
        pointCost: 30,
        title: {
          nl: 'Zichtbare titel (nl)',
          en: 'Visible title (en)',
          fr: 'Visible title (fr)',
        },
        text: {
          nl: 'Verborgen tekst (nl)',
          en: 'Hidden text (en)',
          fr: 'Hidden text (fr)',
        }
      }
    ]);
  }

  const handleDeleteHintClicked = (hintToDelete: QuestionHint) => {
    props.onUpdate(props.hints.filter((hint) => hint.key !== hintToDelete.key));
  }

  const updateHintLanguageDependentProperty = (hintToUpdate: QuestionHint, propertyKey: string, value: any) => {
    var languageDependentProperty = { ...hintToUpdate[propertyKey] };
    languageDependentProperty[props.language] = value;

    updateHintProperty(hintToUpdate, propertyKey, languageDependentProperty);
  }

  const updateHintProperty = (hintToUpdate: QuestionHint, propertyKey: string, value: any) => {
    const updatedHints = props.hints.map((hint) => {
      if (hint.key === hintToUpdate.key) {
        var updatedHint = { ...hintToUpdate };
        updatedHint[propertyKey] = value;

        return updatedHint;
      } else {
        return hint;
      }
    });

    props.onUpdate(updatedHints);
  }

  return (
    <div className="hint-list">
      <h3>Hints:</h3>
      <div className="hint-list-item-wrapper">
        {
          props.hints.map((hint) => {
            return (
              <div key={ hint.key } className="hint-list-item">
                <TextField
                  className="hint-textfield"
                  variant="filled"
                  label="Title"
                  value={ hint.title[props.language] }
                  onChange={ (e) => updateHintLanguageDependentProperty(hint, 'title', e.target.value) }
                  size="small"
                />
                <TextField
                  className="hint-textfield"
                  variant="filled"
                  label="Hint text"
                  value={ hint.text[props.language] }
                  onChange={ (e) => updateHintLanguageDependentProperty(hint, 'text', e.target.value) }
                  size="small"
                />
                <TextField
                  className="hint-number"
                  variant="filled"
                  label="Unlock (min)"
                  value={ hint.minutesUntilUnlocked }
                  onChange={ (e) => updateHintProperty(hint, 'minutesUntilUnlocked', parseInt(e.target.value)) }
                  size="small"
                  type="number"
                />
                <TextField
                  className="hint-number"
                  variant="filled"
                  label="Cost"
                  value={ hint.pointCost }
                  onChange={ (e) => updateHintProperty(hint, 'pointCost', parseInt(e.target.value)) }
                  size="small"
                  type="number" />
                <IconButton onClick={ (e) => handleDeleteHintClicked(hint) }><DeleteIcon /></IconButton>
              </div>
            );
          })
        }
      </div>
      <Button variant="contained" color="primary" onClick={ (e) => onAddHint() }>Add hint</Button>
    </div>
  );
}

export default HintList;

