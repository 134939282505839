import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AddIcon } from "../../../common/components/icons";
import { DefaultDialogTransition } from "../../../helpers/ui";
import { assignPlayersToTeam, createTeam, editTeam, fetchTeamInfo } from "../redux/teamOperations";
import TeamGridItem from "./TeamGridItem";
import UnassignedPlayerList from "./UnassignedPlayerList";

const TeamDialog = (props) => {
  const { open, fetchInfo, challengeUuid } = props;
  const teams = props.teamInfo.teams;
  const unassignedPlayers = props.teamInfo.unassignedPlayers;
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const [selectedUnassignedPlayers, setSelectedUnassignedPlayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (open) {
      fetchInfo(challengeUuid);
      setIsCreatingTeam(false);
    }
    setErrorMessage(null);
  }, [open, fetchInfo, challengeUuid]);

  const close = () => {
    props.onClose();
  }

  const assignPlayersToTeam = (team) => {
    props.assignPlayersToTeam(team, selectedUnassignedPlayers.map((player) => player.uuid));
  }

  return (
    <Dialog open={ props.open } TransitionComponent={ DefaultDialogTransition } onClose={ (e) => { props.onClose() } } maxWidth={ 'md' } fullWidth={ true }>
      <DialogContent>
        <div className="team-dialog dialog-content">
          <h2>{ 'Teams' }</h2>
          <div className="team-info">
            <div className="team-grid">
              { teams.map((team) => {
                return <TeamGridItem
                  key={ team.uuid }
                  team={ team }
                  canSelect={ selectedUnassignedPlayers.length > 0 }
                  onAssignPressed={ () => assignPlayersToTeam(team) }
                />
              })
              }
              { isCreatingTeam
                ? <TeamGridItem key="new" team={ null } canSelect={ false } onCreated={ () => setIsCreatingTeam(false) } />
                : <div />
              }
            </div>
            <UnassignedPlayerList
              players={ unassignedPlayers }
              onSelectionUpdated={ (selection) => setSelectedUnassignedPlayers(selection) }
            />
          </div>

          <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => setIsCreatingTeam(true) }>Add team</Button>
          { errorMessage != null && <p className="error dialog-error">{ errorMessage }</p> }
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => close() }>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    teamInfo: state.team.activeContext.teamInfo,
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchInfo: (challengeUuid) => dispatch(fetchTeamInfo(challengeUuid)),
  createTeam: (team) => dispatch(createTeam(team)),
  editTeam: (team) => dispatch(editTeam(team)),
  assignPlayersToTeam: (team, playerUuids) => dispatch(assignPlayersToTeam({ teamUuid: team.uuid, playerUuids: playerUuids })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDialog);


