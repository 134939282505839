import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.scss';
import App from './App';
import reportWebVitals from './util/reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { bootstrapAuthentication } from "./features/user/redux/userOperations";
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

store.dispatch(bootstrapAuthentication());

const useQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
        <QueryClientProvider client={ useQueryClient } >
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
