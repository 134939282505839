import { Button, Paper } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { SettingsIcon } from "../../../common/components/icons";
import TeamDialog from "./TeamDialog";

const TeamContainer = (props) => {
  const [teamDialogOpen, setTeamDialogOpen] = useState(false);

  const onManageTeamPressed = () => {
    setTeamDialogOpen(true);
  }

  const handleDialogClose = () => {
    setTeamDialogOpen(false);
  }

  return (
    <div>
      <Paper elevation={ 1 } className="team-container">
        <h2>Teams:</h2>

        <div className="team-container-controls">
          <Button variant="contained" color="primary" startIcon={ <SettingsIcon /> } onClick={ (e) => onManageTeamPressed() }>Manage teams</Button>
        </div>
      </Paper>

      <TeamDialog challengeUuid={ props.challengeUuid } open={ teamDialogOpen } onClose={ handleDialogClose } />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);