import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChallengeRepository } from "../data/ChallengeRepository";
import { setChallengeToCopyUuid, setCurrentChallenge, setListForCustomer } from "./challengeSlice";
import { RootState } from "../../../state/store";

const challengeRepository = new ChallengeRepository();

//TODO: type these models that are used

export const fetchList = createAsyncThunk<any, string>(
    'challenge/fetchList',
    async (customerUuid, thunkApi) => {
        try {
            const challengeList = await challengeRepository.fetchList(customerUuid);
            thunkApi.dispatch(setListForCustomer({
                customerUuid: customerUuid,
                list: challengeList,
            }));

            return { success: true };
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
)

export const fetchCurrentChallenge = createAsyncThunk<any, string>(
    'challenge/fetchCurrentChallenge',
    async (challengeUuid, thunkApi) => {
        try {
            const challenge = await challengeRepository.fetchChallenge(challengeUuid);
            thunkApi.dispatch(setCurrentChallenge(challenge));

            return { success: true };
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
)

export const createChallenge = createAsyncThunk<boolean, any>(
    'challenge/create',
    async (createChallengeData, thunkApi) => {
        try {
            const result = await challengeRepository.createChallenge(createChallengeData.customerUuid, createChallengeData.title);
            if (result.success) {
                await thunkApi.dispatch(fetchList(createChallengeData.customerUuid));
                return true;
            } else {
                return thunkApi.rejectWithValue(result.error_code);;
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editChallenge = createAsyncThunk<boolean, any>(
    'challenge/edit',
    async (editChallengeData, thunkApi) => {
        try {
            const result = await challengeRepository.editChallenge(editChallengeData.challenge);
            if (result.success) {
                await thunkApi.dispatch(fetchList(editChallengeData.customerUuid));
                return true;
            } else {
                return thunkApi.rejectWithValue(result.error_code);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


export const deleteChallenge = createAsyncThunk<boolean, any>(
    'challenge/delete',
    async (deleteChallengeData, thunkApi) => {
        try {
            const result = await challengeRepository.deleteChallenge(deleteChallengeData.challenge);
            if (result.success) {
                await thunkApi.dispatch(fetchList(deleteChallengeData.customerUuid));
                return true;
            } else {
                return thunkApi.rejectWithValue(result);
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const activateChallenge = createAsyncThunk<boolean, any>(
    'challenge/activate',
    async (activateChallengeData, thunkApi) => {
        try {
            const result = await challengeRepository.activateChallenge(activateChallengeData.customerUuid, activateChallengeData.challengeUuid);
            if (result.success) {
                await thunkApi.dispatch(fetchList(activateChallengeData.customerUuid));
                return true;
            } else {
                return thunkApi.rejectWithValue(result.error_code);;
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const copyChallenge = createAsyncThunk<boolean, any, { state: RootState }>(
    'challenge/copy',
    async (toCustomerUuid, thunkApi) => {
        try {
            const challengeUuid = thunkApi.getState().challenge.challengeToCopyUuid
            const result = await challengeRepository.copyChallenge(toCustomerUuid, challengeUuid);
            if (result.success) {
                thunkApi.dispatch(setChallengeToCopyUuid(null));
                return true;
            } else {
                return thunkApi.rejectWithValue(result.error);;
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);