import { createAsyncThunk } from "@reduxjs/toolkit";
import { EventRepository } from "../data/EventRepository";
import { Event } from "../data/models";
import { setListForChallenge } from "./eventSlice";

const eventRepository = new EventRepository();

const fetchList = createAsyncThunk(
  'event/fetchList',
  async (challengeUuid: string, thunkApi) => {
    try {
      const eventList = await eventRepository.fetchList(challengeUuid);
      thunkApi.dispatch(setListForChallenge({
        challengeUuid: challengeUuid,
        list: eventList,
      }));

      return eventList;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
)

const editEvent = createAsyncThunk(
  'event/edit',
  async (event: Event, thunkApi: any) => {
    try {
      const challengeUuid = thunkApi.getState().event.currentChallengeUuid;
      const result: any = await eventRepository.editEvent(event);
      if (result.success) {
        await thunkApi.dispatch(fetchList(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const createEvent = createAsyncThunk(
  'event/create',
  async (event: Event, thunkApi: any) => {
    try {
      const challengeUuid = thunkApi.getState().event.currentChallengeUuid;
      const result = await eventRepository.createEvent(challengeUuid, event);

      if (result.success) {
        await thunkApi.dispatch(fetchList(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


const resetEvent = createAsyncThunk(
  'event/reset',
  async (event: Event, thunkApi) => {
    try {
      const result = await eventRepository.resetEvent(event);
      if (result.success) {
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteEvent = createAsyncThunk(
  'event/delete',
  async (event: Event, thunkApi: any) => {
    try {
      const challengeUuid = thunkApi.getState().event.currentChallengeUuid;
      const result = await eventRepository.deleteEvent(event);

      if (result.success) {
        await thunkApi.dispatch(fetchList(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export {
  fetchList,
  editEvent,
  createEvent,
  deleteEvent,
  resetEvent,
};