import { z } from "zod";
import { Language } from "../../../common/components/LanguagePicker";


export enum CustomerType {
  CHALLENGE = 'CHALLENGE',
  JOURNEY = 'JOURNEY',
}

export enum CustomerRegistrationType {
  PLAYER_SELECT = 'PLAYER_SELECT',
  INDIVIDUAL = 'INDIVIDUAL',
  TEAM = 'TEAM',
}

export type CustomerColors = z.infer<typeof CustomerColorsSchema>;
export const CustomerColorsSchema = z.object({
  primaryColor: z.string(),
});

export type Customer = z.infer<typeof CustomerSchema>;
export const CustomerSchema = z.object({
  uuid: z.string(),
  accessCode: z.string(),
  title: z.string(),
  contactEmail: z.string(),
  type: z.nativeEnum(CustomerType),
  colors: CustomerColorsSchema,
  logoUrl: z.optional(z.string()),
  registrationType: z.nativeEnum(CustomerRegistrationType),
  registerRequiresEmail: z.boolean(),
  isSinglePlayer: z.boolean(),
  invoicePerRegistration: z.boolean(),
  availableLanguages: z.array(z.nativeEnum(Language)),
});

