import { ChallengeRepository } from "../data/ChallengeRepository";

// Most challenge services are actually contained within "challengeOperations" since most of it interacts with the redux store
// I learned a lot since then, and the redux store is not that necessary for what we're doing in the challenge components. 
// So at some point, we should migrate away from the redux setup for challenges (and other domains), and move more stuff into the services


const challengeRepository = new ChallengeRepository();

const generateShareUrl = async (challengeUuid: string): Promise<string> => {
  const shareToken = await challengeRepository.generateShareToken(challengeUuid);
  const baseUrl = `${window.location.protocol}//${window.location.host}/share/challenge/`;

  return baseUrl + shareToken;
}

const challengeServices = {
  generateShareUrl,
};

export default challengeServices;