import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class CustomerRepository {

  async fetchList() {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/customer/list',
      {}
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async editCustomer(customer) {
    const postBody = customer;

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/customer/edit',
      {
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async createCustomer(customer) {
    const postBody = customer;

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/customer/create',
      {
        headers: apiHelper.getAuthorizedDefaultHeaders(),
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async fetchPlayers(customer) {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/customer/players?uuid=' + customer.uuid,
      {
        headers: apiHelper.getAuthorizedDefaultHeaders(),
        method: 'GET',
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }

  async updatePlayers(updatePlayerData) {
    const postBody = {
      customerUuid: updatePlayerData.customerUuid,
      deactivatedPlayerUuids: updatePlayerData.deactivatedPlayers.map((player) => player.uuid),
      updatedPlayers: updatePlayerData.updatedPlayers,
      newPlayers: updatePlayerData.newPlayers,
    }

    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/customer/update-players',
      {
        headers: apiHelper.getAuthorizedDefaultHeaders(),
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    const responseBody = await response.json();
    return responseBody;
  }
}