import './assets/app.scss';
import { Redirect, Route, Switch, } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { metisUnitedTheme } from './helpers/themeHelpers';
import LoginScreen from './features/user/component/LoginScreen';
import ChallengeDetailScreen from './features/challenge/component/ChallengeDetailScreen';
import CustomerOverviewScreen from './features/customer/component/CustomerOverviewScreen';
import { JourneyDetailScreen } from './features/journey/component/JourneyDetailScreen';
import ShareChallengeScreen from './features/share/component/ShareChallengeScreen';

const SHARE_CHALLENGE_BASE_ROUTE = '/share/challenge/';

const App = (props) => {
  document.title = 'Metis United Dashboard';

  return (
    <ThemeProvider theme={ metisUnitedTheme }>
      <div className="app">
        <Switch>
          <Route exact path={ SHARE_CHALLENGE_BASE_ROUTE + ':shareToken' } component={ ShareChallengeScreen } />
          { props.isLoggedIn
            ?
            <>
              <Route exact path="/overview" component={ CustomerOverviewScreen } />
              <Route path="/challenge/:challengeUuid" component={ ChallengeDetailScreen } />
              <Route path="/journey/:journeyUuid" component={ JourneyDetailScreen } />
              <Redirect to="/overview" />
            </>
            : <>
              <Route exact path="/login" component={ LoginScreen } />
              { <Redirect to="/login" /> }
            </>
          }
        </Switch>

      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state, ownProps) => ({
  isLoggedIn: state.user.auth.loggedIn, //TODO: maybe make an actual selector from this? 
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(App);
