import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { unassignPlayer } from "../redux/teamOperations";


const MemberList = (props) => {
  const onUnassignMember = (member) => {
    props.unassignMember(member);
  }

  return (
    <div className="team-member-list">
      <ul>
        { props.team.members.map(member => {
          return <li key={ member.uuid }>{ member.name } <IconButton onClick={ (e) => onUnassignMember(member) }><DeleteIcon /></IconButton></li>
        }) }
      </ul>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({
  unassignMember: (member) => dispatch(unassignPlayer(member)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);