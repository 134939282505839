import { Button, Checkbox, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import { useState } from "react";

import LanguagePicker, { Language } from "../../../common/components/LanguagePicker";
import { DefaultDialogTransition } from "../../../helpers/ui";
import { ScreenMultipleChoiceComponent } from "../../screen_component/domain/models";
import MultipleChoiceAnswerList from "./MultipleChoiceAnswerList";

interface MultipleChoiceDialogProps {
  component: ScreenMultipleChoiceComponent,
  languages: Language[],
  onSave: (component: ScreenMultipleChoiceComponent) => void,
  onClose: () => void,

}

const MultipleChoiceDialog = (props: MultipleChoiceDialogProps) => {
  const component = props.component;
  const [language, setLanguage] = useState(props.languages[0])
  const [points, setPoints] = useState(component.points);
  const [incorrectDeductionPoints, setIncorrectDeductionPoints] = useState(component.incorrectDeductionPoints ? component.incorrectDeductionPoints : 0);
  const [maxAnswers, setMaxAnswers] = useState(component.maxAnswers ? component.maxAnswers : 0);
  const [useSounds, setUseSounds] = useState(component.useSounds ? component.useSounds : false);
  const [useConfetti, setUseConfetti] = useState<boolean>(component.useConfetti !== undefined ? component.useConfetti : true);
  const [answers, setAnswers] = useState(component.answers);

  const onConfirm = () => {
    const updatedComponent = {
      ...props.component,
      points: points,
      incorrectDeductionPoints: incorrectDeductionPoints,
      maxAnswers: maxAnswers,
      useSounds: useSounds,
      useConfetti: useConfetti,
      answers: answers,
    }

    props.onSave(updatedComponent);
    props.onClose();
  }

  return (
    <Dialog open={ true } TransitionComponent={ DefaultDialogTransition } maxWidth="lg" onClose={ (e) => { props.onClose() } }>
      <DialogContent>
        <div className="question-dialog dialog-content multiple-choice-dialog">
          <div className="settings-header">
            <h2>Multiple choice settings</h2>
            <LanguagePicker currentLanguage={ language } languages={ props.languages } onChange={ setLanguage } />
          </div>
          <h3>Basic settings:</h3>
          <div className="basic-settings">
            <div className="points">
              <TextField variant="filled" label="Points" value={ points } type="number" onChange={ (e) => setPoints(parseInt(e.target.value)) } size="small" />
            </div>
            <div className="incorrect-deduction-points">
              <TextField variant="filled" label="Points for wrong answer" value={ incorrectDeductionPoints } type="number" onChange={ (e) => setIncorrectDeductionPoints(parseInt(e.target.value)) } size="small" />
            </div>
            <div className="max-answers">
              <TextField variant="filled" label="Max answers" value={ maxAnswers } type="number" onChange={ (e) => setMaxAnswers(parseInt(e.target.value)) } size="small" />
            </div>
            <div className="use-sounds">
              <span>Sounds</span>
              <Checkbox
                checked={ useSounds }
                onChange={ (e) => setUseSounds(e.target.checked) }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
            <div className="use-confetti">
              <span>Confetti</span>
              <Checkbox
                checked={ useConfetti }
                onChange={ (e) => setUseConfetti(e.target.checked) }
                inputProps={ { 'aria-label': 'primary checkbox' } }
                color="primary"
              />
            </div>
          </div>
          <MultipleChoiceAnswerList languages={ props.languages } language={ language } answers={ answers } onUpdate={ (answers) => setAnswers(answers) } />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={ (e) => props.onClose() }>
          Cancel
        </Button>
        <Button onClick={ (e) => onConfirm() }>
          Save
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default MultipleChoiceDialog;