import { CreateSnackScreenData, EditSnackImageScreenData, EditSnackVideoScreenData } from "../data/models";
import { SnackScreenRepository } from "../data/SnackScreenRepository";
import { SnackScreen } from "./models";


const snackScreenRepository = new SnackScreenRepository();

const getList = async (snackUuid: string): Promise<SnackScreen[]> => {
  return await snackScreenRepository.getListBySnackUuid(snackUuid);
}

const createSnackScreen = async (data: CreateSnackScreenData): Promise<void> => {
  return await snackScreenRepository.create(data);
}

const editSnackImageScreen = async (data: EditSnackImageScreenData): Promise<void> => {
  return await snackScreenRepository.edit(data);
}

const editSnackVideoScreen = async (data: EditSnackVideoScreenData): Promise<void> => {
  return await snackScreenRepository.edit(data);
}

const deleteSnackScreen = async (uuid: string): Promise<void> => {
  return await snackScreenRepository.delete(uuid);
}

const snackScreenServices = {
  getList,
  createSnackScreen,
  editSnackImageScreen,
  editSnackVideoScreen,
  deleteSnackScreen,
}

export default snackScreenServices;