import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    currentChallengeUuid: null,
    currentEventList: [],
  },
  reducers: {
    clearCurrent: (state) => {
      state.currentEventList = [];
      state.currentChallengeUuid = null;
    },
    setListForChallenge: (state, action) => {
      state.currentEventList = action.payload.list;
      state.currentChallengeUuid = action.payload.challengeUuid;
    },
  }
});

export const {
  setListForChallenge,
  clearCurrent,
} = eventSlice.actions;

export default eventSlice.reducer;