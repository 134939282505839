import { CreateJourneyData, EditJourneyData } from "./models";
import * as apiHelper from "../../../helpers/apiHelpers";
import ApplicationConfig from "../../../ApplicationConfig";
import { ApiError } from "../../../common/domain/models";
import { Journey, JourneyDetail, JourneyDetailSchema } from "../domain/models";
import zodHelpers from "../../../helpers/zodHelpers";

export class JourneyRepository {

  async fetchListByCustomerUuid(customerUuid): Promise<Journey[]> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/journey/list?customer_uuid=' + customerUuid,
      {}
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      //TODO: parse zod
      return responseBody;
    }

    throw new ApiError('Error fetching journey list', response);
  }

  async fetchByUuid(uuid): Promise<JourneyDetail> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/journey/item/' + uuid,
      {}
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();

      return zodHelpers.parseRequestBody<JourneyDetail>(responseBody, JourneyDetailSchema);
    }

    throw new ApiError('Error fetching journey', response);
  }

  async create(data: CreateJourneyData): Promise<boolean> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/journey/create',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody.success;
    }

    throw new ApiError('Error creating journey', response);
  }

  async edit(data: EditJourneyData): Promise<boolean> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/journey/edit',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody.success;
    }

    throw new ApiError('Error updating journey', response);
  }

  async delete(uuid: string): Promise<boolean> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/journey/delete',
      {
        method: 'POST',
        body: JSON.stringify({ uuid }),
      }
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody.success;
    }

    throw new ApiError('Error deleting journey', response);
  }

  async activate(customerUuid: string, journeyUuid: string): Promise<boolean> {
    const response = await apiHelper.authenticatedFetch(
      ApplicationConfig.BACKEND_URL + '/journey/activate',
      {
        method: 'POST',
        body: JSON.stringify({ customerUuid, journeyUuid }),
      }
    );

    if (response && response.status === 200) {
      const responseBody = await response.json();
      return responseBody.success;
    }

    throw new ApiError('Error activating journey', response);
  }
}