import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { createEvent, deleteEvent, editEvent, resetEvent } from "../redux/eventOperations";
import ScreenListItem from "../../screen/component/ScreenListItem";
import { ScreenRepository } from "../../screen/data/ScreenRepository";
import LanguagePicker, { LanguageContext } from "../../../common/components/LanguagePicker";
import { AddIcon, DeleteIcon, EditIcon, RemoveIcon, SettingsIcon, WarningIcon } from "../../../common/components/icons";
import { extraConfirm } from "../../../util/promptUtil";
import { Event, EventTimings } from "../data/models";
import { EventSettingsDialog } from "./EventSettingsDialog";
import moment from "moment";


const EventListItem = (props) => {
  const event: Event = props.event;
  const [isEditing, setIsEditing] = useState(event === null);
  const [title, setTitle] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [screens, setScreens] = useState([]);
  const [isCreatingScreen, setIsCreatingScreen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(props.languages[0]);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);

  const updateScreens = useCallback(async () => {
    try {
      const screenRepository = new ScreenRepository();
      const screenList = await screenRepository.fetchList(event.uuid);
      setScreens(screenList);
    } catch (e) {
      console.log('error fetching screens:', e);
    }
  }, [event]);

  useEffect(() => {
    if (isOpened) {
      updateScreens();
    }
  }, [isOpened, updateScreens]);

  useEffect(() => {
    if (event) {
      setTitle(event.title ? (event.title[currentLanguage] ? event.title[currentLanguage] : '') : '');
    } else {
      setTitle('');
    }

  }, [event, currentLanguage]);

  const saveTitle = () => {
    setIsEditing(false);
    if (event != null) {
      var eventToEdit = { ...event, title: { ...event.title } };
      eventToEdit.title[currentLanguage] = title;

      props.editEvent(eventToEdit);
    } else {
      var newEvent = {
        title: {},
        weight: 0,
      }

      newEvent.title[currentLanguage] = title;

      props.createEvent(newEvent);
      props.onCreated();
    }
  }

  const handleSettingsSaved = (duration: number | null, timings: EventTimings | null, emailPlayerAfterCompletion: boolean) => {
    let eventToEdit = {
      ...event,
      duration: duration,
      timings: timings,
      emailPlayerAfterCompletion: emailPlayerAfterCompletion,
    };

    props.editEvent(eventToEdit);
    setSettingsDialogOpen(false);
  }

  const onCreateNewScreen = (title, type) => {
    const screenRepository = new ScreenRepository();
    screenRepository.createScreen(event.uuid, {
      title: title,
      type: type,
      data: { components: [] }
    }).then((result) => {
      setIsCreatingScreen(false);
      updateScreens();
    })
  }

  const handleResetClicked = () => {
    if (extraConfirm('Are you sure? This will erase ALL event player data (to confirm, type: yes)')) {
      props.resetEvent(event);
    }
  }

  const handleDeleteClicked = () => {
    if (extraConfirm('Are you sure? This will delete the event AND erase ALL data related to this event, including screens & player progress (to confirm, type: yes)')) {
      props.deleteEvent(event);
    }
  }

  return (
    <Paper elevation={ 2 } className="event-list-item">
      <LanguageContext.Provider value={ currentLanguage }>
        <div className="event-list-item-header">
          { isEditing
            ? <TextField placeholder={ 'Event title (' + currentLanguage + ')' } value={ title } onChange={ (e) => setTitle(e.target.value) } onKeyPress={ (e) => { if (e.key === 'Enter') saveTitle() } } autoFocus />
            : <div>
              <strong className="text-content">Event: { title }</strong>
              <IconButton onClick={ (e) => setIsEditing(true) }><EditIcon /></IconButton>
              <IconButton onClick={ (e) => handleDeleteClicked() }><DeleteIcon /></IconButton>
            </div>
          }
          {
            props.event &&
            <div>
              <LanguagePicker currentLanguage={ currentLanguage } languages={ props.languages } onChange={ (language) => setCurrentLanguage(language) } />
              <IconButton color="primary" onClick={ (e) => setIsOpened(!isOpened) }>{ isOpened ? <RemoveIcon /> : <AddIcon /> }</IconButton>
            </div>
          }
        </div>

        { isOpened &&
          <div>
            <div className="event-list-item-screens">
              {/* TODO: maybe move this and all related logic to separate screen list component? */ }
              { screens.map((screen: any) => {
                return <ScreenListItem key={ screen.uuid } screen={ screen } availableLanguages={ props.languages } onUpdate={ updateScreens } />
              }) }
              { isCreatingScreen
                && <ScreenListItem key="new" onConfirmCreate={ onCreateNewScreen } availableLanguages={ props.languages } />
              }
            </div>
            <div className="event-list-item-controls">
              <div className='event-settings-wrapper'>
                <Button variant="contained" color="primary" startIcon={ <SettingsIcon /> } onClick={ (e) => setSettingsDialogOpen(true) }>Settings</Button>
                { event.timings && <div>
                  <p>
                    <EventTimingInfo title='Active' from={ event.timings.activeFrom } until={ event.timings.activeUntil } /><br />
                    <EventTimingInfo title='Visible' from={ event.timings.visibleFrom } until={ event.timings.visibleUntil } /><br />
                    { event.duration && <><strong>{ 'Duration: ' }</strong>{ event.duration + ' min' }</> }
                  </p>
                </div>
                }
              </div>
              <div className="event-list-item-controls-right">
                <Button variant="contained" color="primary" startIcon={ <WarningIcon /> } endIcon={ <WarningIcon /> } onClick={ (e) => handleResetClicked() }>Reset</Button>
                <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ (e) => setIsCreatingScreen(true) }>New screen</Button>
              </div>
              { settingsDialogOpen && <EventSettingsDialog event={ event } onSave={ handleSettingsSaved } onCancel={ () => setSettingsDialogOpen(false) } /> }
            </div>
          </div>
        }
      </LanguageContext.Provider>
    </Paper >
  )
}

const mapStateToProps = (state) => ({
  languages: state.challenge.currentChallenge.customer.availableLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  editEvent: (event: Event) => dispatch(editEvent(event)),
  createEvent: (event: Event) => dispatch(createEvent(event)),
  resetEvent: (event: Event) => dispatch(resetEvent(event)),
  deleteEvent: (event: Event) => dispatch(deleteEvent(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventListItem);

interface EventTimingInfoProps {
  title: string,
  from: Date,
  until: Date,
}

function EventTimingInfo({ title, from, until }: EventTimingInfoProps) {
  const format = 'DD/MM/YYYY HH:mm';
  const fromString = moment(from).format(format);
  const untilString = moment(until).format(format);

  return <> <strong>{ title + ': ' }</strong>{ fromString + ' - ' + untilString }</>;
}